import React, { useEffect, useState } from "react";
import { TrendKeywordStyles as Styled } from "../styles/TrendKeywordStyle/TrendKeyword.style";
import TrendKeywordInfo from "./TrendKeywordInfo";
import {
  Title as MarketName,
} from "../styles/CommonStyle/Common.style";
import useBasics from "hooks/useBasics";
import { IMarket } from "store/modules/types";
import useTrendKeyword from "hooks/useTrendKeyword";
interface IProps {
  searchDate: string;
}
const TrendKeyword: React.FC<IProps> = (props) => {
  const { searchDate } = props;
  const {
    basics : {
      markets
    }
  } = useBasics()

  const { 
    trendKeyword : {
      failedKeywordData
    },
    requestFailedTrendKeyword,
  } = useTrendKeyword();

  const [currentMall, setCurrentMall] = useState<IMarket>();

  const failed = failedKeywordData?.list.map(d => d) || []

  useEffect(() => {
    const callback = (succeeded: boolean, res: any) => {
      if (!succeeded) {
        alert('오류가 발생하였습니다.')
        //console.log(res)
      }
    };

    requestFailedTrendKeyword({
      queryParameters: { date : searchDate },
      callback: callback
    }) 
  }, [searchDate])
    
  return (
    <>
      <Styled.Section>
        <Styled.ButtonContainer>
          {!!markets && !!markets.list && markets.list.map((market, index) => {
            if (!currentMall && index === 0)  setCurrentMall(market)
              
            return <Styled.Button
              key={index}
              selected={market.marketId === currentMall?.marketId}
              missingKeyword={failed.includes(market.marketId)}
              onClick={() => {
                setCurrentMall(market);
              }}
            >
              {market.name}
            </Styled.Button>
          })}
        </Styled.ButtonContainer>
      </Styled.Section>
      <MarketName>{currentMall?.name ?? '-'}</MarketName>
      <TrendKeywordInfo
        searchDate={searchDate}
        mallId={currentMall?.marketId ?? ''}
      />
    </>
  );
};
export default TrendKeyword;
