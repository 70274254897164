import React, { useState, SyntheticEvent, useEffect, useMemo } from 'react';

import { Button, Select, Form, Message, Table, Icon } from 'semantic-ui-react';

import BasePopup, { IPopupProps, PopupTypes } from 'components/common/popup/BasePopup';

// Hooks
import useSalonon from 'hooks/useSalonon';

import ImageUpload from 'components/common/fileuploader/ImageUploader';
import Ckeditor4 from 'components/common/ckeditor4'

import '../interfaces'
import { IInsertDsonParams, initInsertParams, IFiles } from '../interfaces';
import TagList from './TagList'
import PostList from './PostList'
import useDidMountEffect from 'hooks/useDidMountEffect';


export const TagPopupTypes = {
  MANAGE: 'manage',
  SELECT: 'select',
} as const
type TagPopupType = typeof TagPopupTypes[keyof typeof TagPopupTypes]

interface IInsertQnaParams {
  qnaCategoryType: string,
  qnaContents: string,
  qnaTitle: string,
  qnaType: string,
  requestEmail: string,
  userId: string
}

interface IProps {
  category: any[];
  user: any;
  open: boolean;
  postId : number;
  onCreateQna: Function;
  onRequest: Function;
  onClose(): void;
  showFeedback: Function;
}

const SalonOnWrite: React.SFC<IProps> = (props) => {

  const { category, user, open, postId, onCreateQna, onRequest, onClose, showFeedback } = props;

  const { requestCreateDson, requestDsonDetail, requestModifyDson } = useSalonon();


  const [ insertDsonParams, setInsertDsonParams] = useState<IInsertDsonParams>(initInsertParams);

  const [ openTagSelect, setOpenTagSelect ] = useState(false);
  const [ insertedTag, setInsertedTag ] = useState([]);

  const [ openPostSelect, setOpenPostSelect ] = useState(false);
  const [ insertedPost, setInsertedPost ] = useState([]);

  const [ editor, setEditor ] = useState<JSX.Element>(<></>)

  useEffect(() => {    
    if(postId !== -1){
      requestDsonDetail({
        pathVariables: {
          postsId: postId
        }, callback: (succeeded: boolean, res: any) => {
          if(succeeded){
            // console.log('res.response ::::', res.response);
            const data = res.response.data

            // const tagIdArray = data.map((item:any) => item.hashtagId)
            // let tagIdStr = tagIdArray.join(',');
            // console.log('data :::: ', data);
            setInsertDsonParams({    
              ...insertDsonParams,      
              mainCategoryCode: data.categoryCode,
              mainTitle: data.mainTitle,
              subTitle: data.subTitle,
              contents: data.contents,
              thumbnailFileName: data.thumbnailFileName,
              thumbnailFilePath: data.thumbnailFilePath,
              topImageName: data.topImageName,
              topImagePath: data.topImagePath,
              iconType: data.iconType,
              postsTargetIds: data.salonRelationPosts && data.salonRelationPosts.length > 0 ? data.salonRelationPosts.map((post:any) => post.postsId) : [],
              hashTagId: changeArrayToString('hashtagId', []),
              viewYn: data.viewYn,
              userId: user.userId,
              salonPostsId: postId,
              
            });
            setInsertedPost(data.salonRelationPosts);
            setInsertedTag([]);            
          }
        }
      });
    } else {
      setInsertDsonParams({
        ...insertDsonParams,
        userId: user.userId
      })
    }
  }, []);

  useDidMountEffect(() => {
    setEditor(postId === -1 ?
      <Ckeditor4 
        url="DSON"
        setContents={onEditorChange}
        height={400}
      />
    : postId > 0 ?
      <Ckeditor4 
        url="DSON"
        contents={insertDsonParams.contents}
        setContents={onEditorChange}
        height={400}
      />
    : <></>)
  }, [insertDsonParams])

  // const changeObjectFormat
  

  const onOpenTagSelect = () => {
    setOpenTagSelect(true);
  }

  const onCloseTagSelect = () => {
    setOpenTagSelect(false);
  }
  
  const onOpenPostSelect = () => {
    setOpenPostSelect(true);
  }

  const onClosePostSelect = () => {
    setOpenPostSelect(false);
  }

  const onCreateDson = (e: SyntheticEvent, item: any) => {    
    const id = (item && item.data) || undefined;
    if (id) {
      console.log('Create insertDsonParams ', insertDsonParams);
      requestCreateDson({
        body : {
          ...insertDsonParams,
          categoryCode: insertDsonParams.mainCategoryCode
        }, 
        callback : (succeeded: boolean, res: any) => {
          if(succeeded){
            onRequest();
            showFeedback('Item is registered');
          }            
        }
      });
    }
    onClose();
  }
  

  const onModifyDson = (e: SyntheticEvent, item: any) => {    
    const id = (item && item.data) || undefined;
    if (id) {
      console.log('Modify insertDsonParams ', insertDsonParams);
      requestModifyDson({
        body : {
          ...insertDsonParams,
          categoryCode: insertDsonParams.mainCategoryCode
        }, 
        callback : (succeeded: boolean, res: any) => {
          if(succeeded){
            // console.log('succeeded modify');
            // onRequest(initRequestParams);
            // setRequestParams(initRequestParams);
            showFeedback('Item is Modified');
          }            
        }
      });
    }
    onClose();
  }

  const onEditorChange = (data:any) => {
    setInsertDsonParams({
      ...insertDsonParams,
      contents: data
    });
  }

  const onChangeParams = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) =>{
    // console.log('data : ', data);
    setInsertDsonParams({
      ...insertDsonParams,
      [data.name]: data.value
    });
    // console.log('insertQnaParams :: ', insertQnaParams);
  }

  const onSetThumbnailFile = (file:IFiles) => {
    setInsertDsonParams({
      ...insertDsonParams,
      thumbnailFileName: file.fileName,
      thumbnailFilePath: file.filePath,
    })
  }
  

  const onSetTopImageFile = (file:IFiles) => {
    setInsertDsonParams({
      ...insertDsonParams,
      topImageName: file.fileName,
      topImagePath: file.filePath,
    })    
  }

  const onDeleteInsertedTag = (tagId: number) => {
    const filtered = insertedTag.filter(
      (item:any) => item.hashtagId !== tagId
    )
    onSetInsertedTag(filtered);
  }

  const onDeleteInsertedPost = (postId: number) => {
    const filtered = insertedPost.filter(
      (item:any) => item.postsId !== postId
    )
    onSetInsertedPost(filtered);  
  }

  const changeArrayToString = (key:any, data:any) => {
    const idArray = data.map((item:any) => item[key])
    let idStr = idArray.join(',');

    return idStr;
  }

  const onSetInsertedTag = (data:any) => {
    setInsertedTag(data);
    const idStr = changeArrayToString('hashtagId', data);
    // console.log('idStr :', idStr);
    setInsertDsonParams({
      ...insertDsonParams,
      hashTagId: idStr
    })
  }
  

  const onSetInsertedPost = (data:any) => {
    setInsertedPost(data);

    setInsertDsonParams({
      ...insertDsonParams,
      postsTargetIds: data
    })
  }

  const setEllipsisText = (text:string, length: number) => {
    let ellipsisText = '';
    if (text.length > length)
      ellipsisText = text.slice(0,length) + '...';
    else
      ellipsisText = text;

    return ellipsisText;
  }
  
  // console.log('insertDsonParams :', insertDsonParams );
  // console.log('thumbnailFile: ', thumbnailFile)
  // console.log('topImageFile: ', topImageFile)
  // console.log('insertedTag.length', insertedTag.length );

  const tagSelectPopup = openTagSelect ? (<>
    <TagList 
      user= {user}
      open= {openTagSelect}
      onCreateQna= {()=>{alert('create')}}
      onClose= {onCloseTagSelect}
      type={TagPopupTypes.SELECT}
      insertedTag={insertedTag}
      setInsertedTag={onSetInsertedTag}
    />
  </>) : null;
  
  const postSelectPopup = openPostSelect ? (<>
    <PostList 
      postId = {postId}
      user= {user}
      category= {category}
      open= {openPostSelect}
      onCreateQna= {()=>{alert('create')}}
      onClose= {onClosePostSelect}
      type={TagPopupTypes.SELECT}
      insertedPost={insertedPost}
      setInsertedPost={onSetInsertedPost}
    />
  </>) : null;
  
  const insertedTagList = insertedTag.length > 0 ? insertedTag.map((item:any, index) => 
    <Button key={index} style={{margin:'5px', padding:'6px'}} basic content={item.hashtagName} icon='delete' labelPosition='right' onClick={()=>onDeleteInsertedTag(item.hashtagId)} />
  ) : null

  
  // const insertedPostList = insertedPost.length > 0 ? insertedPost.map((item:any) => 
  //   <Button style={{margin:'5px', padding:'6px'}} basic content={setEllipsisText(item.mainTitle, 10)} icon='delete' labelPosition='right' onClick={()=>onDeleteInsertedPost(item.postsId)} />
  // ) : null
  // console.log('insertDsonParams.postsTargetId : ', insertDsonParams.postsTargetId);
  // console.log('category : ', category);

  // const relationPostTableHeaders = postTableHeaders.map((item, index)=>
  //   <Table.HeaderCell key={index}>{item}</Table.HeaderCell>
  // )

  const relationPostItemlist = insertedPost.length > 0 ? insertedPost.map((item:any, index)=>
    <Table.Row className="row" key={index}>
      <Table.Cell className="id" >{item.postsId}</Table.Cell>
      <Table.Cell className="title" >{setEllipsisText(item.mainTitle, 50)}</Table.Cell>
      <Table.Cell className="remove" ><Icon className="icon" name="remove circle" color="red"  onClick={()=>onDeleteInsertedPost(item.postsId)} link /></Table.Cell>
    </Table.Row>
  ) : <Table.Cell style={{textAlign:'center'}}>연관 포스트 없음</Table.Cell>
  
  // const editor = useMemo(() => 
  //   postId === -1 ?
  //     <Ckeditor4 
  //       url="DSON"
  //       setContents={onEditorChange}
  //       height={400}
  //     />
  //   : postId > 0 ?
  //     <Ckeditor4 
  //       url="DSON"
  //       contents={insertDsonParams.contents}
  //       setContents={onEditorChange}
  //       height={400}
  //     />
  //   : null
  // , [insertDsonParams]);

  return (
    <BasePopup
      open={open}
      id={user.uid}
      size='large'
      type={PopupTypes.DIALOG}
      title={postId === -1 ? '공간ON 등록' : '공간ON 수정'}
      buttons={[
        {}, { onClick: postId === -1 ? onCreateDson : onModifyDson }
      ]}
      onClose={onClose}
    >
      <Form size='small'>
        <Form.Group >
          <Form.Select
            width='4'
            label='카테고리'
            name='mainCategoryCode'
            value={insertDsonParams.mainCategoryCode}
            options={category}
            placeholder='선택'
            onChange={onChangeParams}
          />

          <Form.Select
            width='4'
            label='썸네일 라벨'
            name='iconType'
            // value={valueToText(thumbnailLabel, insertDsonParams.iconType)}
            value={insertDsonParams.iconType}
            options={thumbnailLabel}
            placeholder='선택'
            onChange={onChangeParams}
          />

          <Form.Select
            width='4'
            label='게시물 노출 여부'
            name='viewYn'
            // value={valueToText(thumbnailLabel, insertDsonParams.iconType)}
            value={insertDsonParams.viewYn}
            options={viewYnList}
            placeholder='선택'
            onChange={onChangeParams}
          />
        </Form.Group>

        <Form.Input
          label='제목'
          name='mainTitle'
          placeholder='제목을 적어주세요'
          value={insertDsonParams.mainTitle}
          onChange={onChangeParams}
        />
        
        <Form.TextArea
          label='소개글'
          name='subTitle'
          placeholder='내용을 적어주세요'
          value={insertDsonParams.subTitle}
          onChange={onChangeParams}
        />

        <Form>
          <label className="label">썸네일 이미지 등록</label>
          <div className="image-uploader">
            <ImageUpload 
              fileType={"DSON"} 
              setFile={onSetThumbnailFile} 
              defaultImage={insertDsonParams.thumbnailFilePath+insertDsonParams.thumbnailFileName} 
            />
          </div>
        </Form>

        <Form>
          <label className="label">상단 이미지 등록</label>
          <div className="image-uploader">
            <ImageUpload 
              fileType={"DSON"} 
              setFile={onSetTopImageFile} 
              defaultImage={insertDsonParams.topImagePath+insertDsonParams.topImageName} 
            />
          </div>
        </Form>
        
        <Form>
          <label className="label">내용</label>
          <div className="image-uploader">            
            {editor}
            {/* <Editor id={postId} url="dson" name={'contents'} height='25em' contents={insertDsonParams.contents} onChangeParams={onChangeParams} /> */}
          </div>
        </Form>
        
        <Form>
          <label className="label">해시태그 등록</label>
          <div style={{display:'flex'}}>
            <div className="image-uploader" style={{flex: '9', height:'150px'}}>
              {insertedTagList}
            </div>
            <div style={{flex:'1', marginBottom:'1em', padding:'2em' }}>
              <Button color='grey' inverted style={{verticalAlign:'middle', color:'grey'}} content='삽입' onClick={onOpenTagSelect} />            
            </div>
          </div>
        </Form>
        
        <Form>
          <label className="label">연관포스트 등록</label>
          <div style={{display:'flex'}}>
            <div className="image-uploader" style={{flex: '9', height:'150px', overflowY:'scroll'}}>



              <Table className="relation-post-table" >
                <Table.Header>
                  <Table.Row className="row">
                    <Table.HeaderCell className="id">Post ID</Table.HeaderCell>
                    <Table.HeaderCell className="title">제목</Table.HeaderCell>
                    <Table.HeaderCell className="remove"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>



                <Table.Body>
                  {relationPostItemlist}
                </Table.Body>

              </Table>


              {/* {insertedPostList} */}
            </div>
            <div style={{flex:'1', marginBottom:'1em', padding:'2em' }}>
              <Button color='grey' inverted style={{verticalAlign:'middle', color:'grey'}} content='삽입' onClick={onOpenPostSelect} />             
            </div>
          </div>
        </Form>
        {tagSelectPopup}
        {postSelectPopup}
      </Form>       
      
    </BasePopup>
  );
}

const thumbnailLabel = [
  { value: 0, text: '표시 안함'},
  { value: 1, text: 'NEW'}
]

const viewYnList = [
  { value: false, text: '아니오'},
  { value: true, text: '예'}
]

// const postTableHeaders = [ 'Post ID', '제목', ' ' ]

// const valueToText = (category:{value:number, text:string}[], value:number) => {
//   const filtered = category.filter((item:any) => item.value === value)
  
//   return filtered.length > 0 ? filtered[0].text : 'none'
// }

export default SalonOnWrite;