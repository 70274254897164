import React, { useEffect, useState } from "react";
import useTrendKeyword from "hooks/useTrendKeyword";
import { TrendKeywordInfoStyles as Styled } from "../styles/TrendKeywordStyle/TrendKeywordInfo.style";
import {
  RecollectButtonContainer,
  CustomizedButton,
} from "../styles/CommonStyle/Common.style";
import DayOfWeek from "../DayOfWeek";
import { MARKET_ADDINFO } from "../constants";
import TrendKeywordTable from "./TrendKeywordTable";

interface IProps {
  searchDate: string;
  mallId: string;
}

const TrendKeywordInfo: React.FC<IProps> = (props) => {
  const { searchDate, mallId } = props;
  const { 
    requestTrendKeyword, 
    requestRecollectTrendKeyword,
  } = useTrendKeyword();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ showAll, setShowAll ] = useState<boolean>(false)
  const today = new Date().toISOString().slice(0, 10);
  const marketAddInfo = MARKET_ADDINFO[mallId];
  const [keywordMissing, setKeywordMissing] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) setIsLoading(true)
    setKeywordMissing(false);
    console.log("before API called keywordMissing is", keywordMissing);
    console.log("changed date or mallId");
    const callback = (succeeded: boolean, res: any) => {
      if(succeeded && res.response.code === 'OK'){
        
      } else {
        
      }

      setIsLoading(false)
    };
    requestTrendKeyword({
      queryParameters: { searchDt: searchDate, marketId: mallId },
      callback: callback
    });
  }, [searchDate, mallId]);

  const onRecollect = () => {
    const severalMarketName = MARKET_ADDINFO[mallId]?.severalMarketName || ''
    const payload: any = {
      queryParameters: { marketName: MARKET_ADDINFO[mallId].severalMarketName },
      callback: (succeded: boolean, res: any) => {
        if (succeded) {
          alert(mallId + " 재수집 요청 성공");
        } else {
          alert(mallId + " 재수집 실패");
        }
      },
    };
    if (severalMarketName) {
      requestRecollectTrendKeyword(payload);
    } else {
      alert('재수집 요청 파라미터가 설정되어 있지 않습니다.')
    }
  };

  const linkClickHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const link = marketAddInfo?.link ?? ''
    if (link) { 
      window.open(link, '_blank', 'noopener,noreferrer') 
    } else {
      alert('인기키워드 연결 링크가 설정되어 있지 않습니다.')
    }
  }

  return (
    <>
      <RecollectButtonContainer>
        <DayOfWeek isFullDate={true} dateString={searchDate}>
          {" "}
        </DayOfWeek>
        {today === searchDate && (
          <CustomizedButton
            height="30px"
            width="60px"
            margin="0px 0px 0px"
            onClick={onRecollect}
          >
            재수집
          </CustomizedButton>
        )}
        <Styled.LinkButton
          as="a"
          //href={link}
          onClick={linkClickHandler}
        >
          {" "}
          인기키워드 연결 링크
        </Styled.LinkButton>
        <Styled.LinkButton
          as="a"
          //href={link}
          onClick={() => setShowAll(prev => !prev)}
        >
          {showAll ? '카테고리 접기' : '카테고리 펼치기'}
        </Styled.LinkButton>
      </RecollectButtonContainer>
      <TrendKeywordTable isLoading={isLoading} showAll={showAll}/>
    </>
  );
};
export default TrendKeywordInfo;
