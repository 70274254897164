import moment from "moment"

export const getDateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth());
  const monthStr = month >= 10 ? month : '0' + month;
  let day = date.getDate();
  const dayStr = day >= 10 ? day : '0' + day;
  return year + '-' + monthStr + '-' + dayStr;
};

export const convertCommonCode = (commonCode:any[]) => {
  return commonCode.map((code) => ({value: code.key, text:code.value}))
}

export const convertDate = (date:string) => {
  return moment(date).format('YY.MM.DD')
}

export const getRage = (startDt:string|null|undefined, endDt:string|null|undefined) => {
  if (!!startDt && !!endDt) {
    return `${convertDate(startDt)}~${convertDate(endDt)}`
  }

  if (!startDt && !!endDt) {
    return `~${convertDate(endDt)}`
  }

  
  if (!!startDt && !endDt) {
    return `~${convertDate(startDt)}`
  }

  return ''
}

export const convertBoolean = (type:boolean) => {
  if (type) {
    return 'Y'
  }

  return 'N'
}

export const findCode = (codes: { key: string, value: string}[] | undefined, key: string) => {
  const item = { key: '', value: '' }
  if (codes) {
    const code = codes.find((code) => code.key === key)
    if (code) return code
  }
  return item
}