import React, { Dispatch, SetStateAction } from 'react';
import { ICrawlApiCallStatsParams } from '../interface';
import { ValidationCrawlApiCallStatStyles as Styled } from '../ValidationCrawlApiCallStat.styles'
import Flatpickr from "react-flatpickr";
import ClearIcon from "components/common/button/Clear";
import { getDateFormatter } from 'util/common';

interface IProps {
  value: string | number | Date | readonly (string | number | Date)[] | undefined
  fieldname: string
  params: ICrawlApiCallStatsParams
  setParams: Dispatch<SetStateAction<ICrawlApiCallStatsParams>>
}


const ValidationCrawlApiCallStatFilterFlatpicker: React.FC<IProps> = (props) => {
  const { value, fieldname, params, setParams } = props
  return <Styled.Flatpicker>
    <Flatpickr
      className="custom-pickr"
      value={value}
      onChange={(date: Date[]) => {
        setParams({
          ...params,
          [fieldname]: date[0] ? getDateFormatter(date[0]) : '',
        })
      }}
      options={{
        mode: "single",
        locale: "ko",
        enableTime: false,
        conjunction: "~",
        dateFormat: 'Y-m-d',
        closeOnSelect: false
      }}
    />
    <ClearIcon callback={() => value && setParams({
      ...params,
        [fieldname]: '',
      })} 
    />
  </Styled.Flatpicker>
}

export default ValidationCrawlApiCallStatFilterFlatpicker