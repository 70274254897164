import React, { useEffect, useState } from "react"
import { ValidationCrawlApiCallStatStyles as Styled } from './ValidationCrawlApiCallStat.styles'
import ValidationCrawlApiCallStatFilter from "./filters/ValidationCrawlApiCallStatFilter"
import useCrawlApiCallStat from "hooks/useCrawlApiCallStat"
import { initCrawlApiCallStatsParams, initValidationCrawlApiCallStatSort, validationCrawlApiCallStatCount } from "./constant"
import { ICrawlApiCallStatsParams, TValidationCrawlApiCallStatCount, TValidationCrawlApiCallStatSort } from "./interface"
import useDidMountEffect from "hooks/useDidMountEffect"
import ValidationCrawlApiCallStatList from "./ValidationCrawlApiCallStatList"
import FullScreenLoader from "components/common/loader/FullScreenLoader"

const ValidationCrawlApiCallStat = () => {
  const [ params, setParams ] = useState<ICrawlApiCallStatsParams>(initCrawlApiCallStatsParams)
  const [ sort, setSort ] = useState<TValidationCrawlApiCallStatSort>(initValidationCrawlApiCallStatSort)
  
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const {
    crawlApiCallStats : {
      crawlApiCallStats
    },
    requestGetCrawlApiCallStats
  } = useCrawlApiCallStat()

  useEffect(() => {
    onRequestGetCrawlApiCallStats()
  }, [])

  useDidMountEffect(() => {
    onRequestGetCrawlApiCallStats(params)
  }, [params])

  const onRequestGetCrawlApiCallStats = (params?: ICrawlApiCallStatsParams) => {
    const reqParmas = params ? params : initCrawlApiCallStatsParams;
    if (!isLoading) setIsLoading(true)
    
    if (reqParmas && reqParmas.page > 0) {
      requestGetCrawlApiCallStats({
        callback: (succeeded: boolean, res: any) => {
          setIsLoading(false)

          if (!succeeded) {
            alert('오류가 발생했습니다.')
          }
        },
        queryParameters: Object.entries(reqParmas).reduce((acc, cur, idx) => {
          const key = cur[0]
          const val = cur[1]

          if (val) {
            return {
              ...acc,
              [key]: val
            }
          }

          return acc
        }, {} )
      })
    }
  } 

  return <Styled.Wrap>
    <ValidationCrawlApiCallStatFilter 
      params={params}
      setParams={setParams}
    />
    {isLoading 
      ? <FullScreenLoader />
      : <Styled.DatasWrap>
          <Styled.InfoWrap>
            {
              Object
                .entries(validationCrawlApiCallStatCount)
                .map(c => <Styled.InfoItem key={`validationCrawlApiCallStatCount-${c[0]}`}>
                  <span>{c[1]}</span>
                  <span>{crawlApiCallStats[c[0] as TValidationCrawlApiCallStatCount]}</span>
                </Styled.InfoItem>)
            }
          </Styled.InfoWrap>
          <ValidationCrawlApiCallStatList 
            sort={sort}
            setSort={setSort}
            params={params}
            setParams={setParams}
            onRequest={onRequestGetCrawlApiCallStats}
          />
        </Styled.DatasWrap>
    }
  </Styled.Wrap>
}

export default ValidationCrawlApiCallStat