
// api를 호출하여 데이터를 조회 하는 방식으로 변경 필요해 보임
export const MARKET_ADDINFO: { [key in string] : {
  link: string
  severalMarketName: string
} } = {
  mall0148 : {
    link: 'https://search.shopping.naver.com/best/category/keyword?categoryCategoryId=ALL&chartRank=1',
    severalMarketName: 'naver'
  }, // 스마트 스토어
  mall0184 : {
    link: '', 
    severalMarketName : ''
  }, // 쿠팡
  mall0010 : {
    link : 'https://m.gmarket.co.kr/#tab_keyword',
    severalMarketName: 'gmarket'
  }, // 지마켓
  mall0003 : {
    link : 'https://m.auction.co.kr/',
    severalMarketName: 'auction'
  }, // 옥션
  mall0025 : {
    link : 'https://www.11st.co.kr/main',
    severalMarketName: 'elevenst',
  }, // 11번가
  mall0287 : {
    link: 'https://search.wemakeprice.com/main',
    severalMarketName: 'wemakeprice'
  },
  mall0182 : {
    link : 'https://home.tmon.co.kr/',
    severalMarketName: 'tmon',
  },
  mall0012 : {
    link: 'https://www.interpark.com/',
    severalMarketName: 'interpark'
  },
  mall0321 : {
    link: '',
    severalMarketName: 'lotteon'
  }, // 롯데온
  mall0286 : {
    link: '',
    severalMarketName: '',
  }, // 카카오 쇼핑
  mall0024 : {
    link: 'https://www.ssg.com/',
    severalMarketName: 'ssg'
  },
  mall0350 : {
    link: '',
    severalMarketName: ''
  }, // 지그재그
  mall0021 : {
    link: 'https://m.gsshop.com/index.gs',
    severalMarketName: 'gsshop',
  }, // gs shop
  mall0033 : {
    link: 'https://m.akmall.com/search/SearchMain.do',
    severalMarketName: 'akmall'
  },
  mall0187 : {
    link: 'https://www.lotteon.com/p/display/main/ellotte?mall_no=2&ch_no=100195&ch_dtl_no=1000617',
    severalMarketName: 'lottemall'
  },
  mall0363 : {
    link: 'https://www.kurly.com/search',
    severalMarketName: 'marketkurly'
  }, // 마켓컬리
  mall0020: {
    link: 'https://display.cjonstyle.com/p/homeTab/main?hmtabMenuId=H00005',
    severalMarketName: 'cjonstyle'
  }, // cj 온스타일
  mall0310: {
    link: 'https://ohou.se/',
    severalMarketName: 'todayshome'
  }, // 오늘의 집
  mall0192: {
    link: 'https://www.musinsa.com/main/musinsa/recommend',
    severalMarketName: 'musinsa'
  }, // 무신사
}
