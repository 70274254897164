import { SORT_TYPE } from "components/common/button/Sort";
import { ICrawlApiCallStatsParams, TCrawlApiCallStatsFilterSelect, TValidationCrawlApiCallStatCount, TValidationCrawlApiCallStatLabelKey, TValidationCrawlApiCallStatSort } from "./interface";

export const initCrawlApiCallStatsParams : ICrawlApiCallStatsParams = {
  startDate: '',
  endDate: '',
  marketId: '',
  serviceType: '',
  rootType: '',
  apiType: '',
  isCrawl: '',
  page: 1,
  size: 10,
  orderBy: '',
}

export const validationCrawlApiCallStatLabels: { key: TValidationCrawlApiCallStatLabelKey, label: string }[] = [
  { key: 'SERVICE_TYPE', label: '서비스타입' },
  { key: 'ROOT_TYPE', label: 'ROOT타입' },
  { key: 'API_TYPE', label: 'API타입' },
  { key: 'MARKET_ID', label: '마켓' },
  { key: 'IS_CRAWL', label: '크롤호출' },
  { key: 'DATE', label: '날짜' },
  { key: 'COUNT', label: '카운트' },
]

export const initValidationCrawlApiCallStatSort: TValidationCrawlApiCallStatSort = {
  SERVICE_TYPE : '',
  ROOT_TYPE : '',
  API_TYPE : '',
  MARKET_ID : '',
  IS_CRAWL : '',
  DATE : '',
  COUNT : '',
}

export const validationCrawlApiCallStatCount: { [ key in TValidationCrawlApiCallStatCount ] : string } = {
  totalCountSum: '전체 콜수',
  trueCountSum: '크롤 호출',
  falseCountSum: '외부 호출'
}

export const validationCrawlApiCallStatFilterSelect: { [ key in TCrawlApiCallStatsFilterSelect ] : string } = {
  serviceType: '서비스 타입',
  rootType: 'ROOT 타입',
  apiType: 'API 타입',
  marketId: '마켓',
  isCrawl: '크롤 호출'
}