import React from 'react'

import TableListItem, { ITableListItemProps, ITableListItemIconButton } from 'components/common/table/TableListItem'
import useBasics from 'hooks/useBasics';
import { ICrawlApiCallStat } from 'store/modules/types';
import { convertBoolean, convertDate, findCode } from 'util/common';


interface IProps {
  idx: number
  item: ICrawlApiCallStat;
}

const ValidationCrawlApiCallStatListItem: React.FC<IProps> = (props) => {
  const { 
    idx, 
    item: { 
      serviceType,
      rootType,
      apiType,
      marketId,
      date,
      count,
      isCrawl,
    }
  } = props

  const { 
    basics: { 
      commonCodeList, 
      markets 
    }
  } = useBasics();

  const market = markets.list.find(market => market.marketId === marketId);

  const tableListItemsProps: ITableListItemProps = {
    id: idx,
    items: [ 
      findCode(commonCodeList.service_type, serviceType).value ?? '-', 
      findCode(commonCodeList.crawl_api_call_type, rootType).value ?? '-', 
      findCode(commonCodeList.crawl_api_call_type, apiType).value ?? '-', 
      `${market?.name ?? '-'} (${market?.marketId ?? '-'})`, 
      convertBoolean(isCrawl), 
      convertDate(date), 
      count,
    ],
  };

  return (
    <TableListItem 
      {...tableListItemsProps} 
    />
  )
}

export default ValidationCrawlApiCallStatListItem