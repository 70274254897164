import React, { useCallback, useState } from "react"

import { CategoryName, StyledTd, StyledTdItem, TrendKeywordTooltipWrap } from "../styles/CommonStyle/Common.style";
import { KeywordRankList } from "store/modules/types";
import useHover from "hooks/useHover";
import CommonTooltip from "components/common/tooltip/CommonTooltip";

interface IProps {
  keywordRankList : KeywordRankList | undefined
  showAll?: boolean
  isLastRank: boolean
  isFirstHour: boolean
}

const TrendKeywordItem: React.FC<IProps> = (props) => {
  const { keywordRankList, showAll, isLastRank, isFirstHour } = props
  const { ref, isHover } = useHover<HTMLTableCellElement>()

  const categoryName = keywordRankList?.categoryName ?? ''
  const categoryId = keywordRankList?.categoryId ?? ''
  const splitCategoryName = categoryName.split('>')
  const lastCategoryName = splitCategoryName[splitCategoryName.length-1]

  return <StyledTd 
    ref={ref}
    isEmpty={!keywordRankList || !lastCategoryName || !categoryId}
  >
    <StyledTdItem>
      <span>{keywordRankList?.keyword ?? ''}</span>
      <CategoryName>
        {
          showAll 
            ? categoryName ?? '-' 
            : lastCategoryName ?? '-'
        }
      </CategoryName>
    </StyledTdItem>
    {lastCategoryName && isHover && <CommonTooltip offset={{ top: isLastRank ? '-50px' :'30px', left: isFirstHour ? '-150px' : '-100px' }}>
        <TrendKeywordTooltipWrap>
          <span>{keywordRankList?.keyword ?? '-'}</span>
          <span>{keywordRankList?.categoryName}</span>
          <span>{keywordRankList?.categoryId}</span>
        </TrendKeywordTooltipWrap>
      </CommonTooltip>
    }
  </StyledTd>
}

export default TrendKeywordItem