import React, { CSSProperties } from "react"
import styled, { css } from "styled-components"

const Wrap = styled.div<IProps>`
  position: absolute;
  border-radius: 7px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  padding: 10px;

  z-index: 100000;
  

  ${({ offset }) => ({...offset})};
`

export interface ITooltipOffset {
  top?: string
  left?: string
  right?: string
  bottom?: string
}

interface IProps {
  offset: ITooltipOffset
  children: JSX.Element
}

const CommonTooltip:React.FC<IProps> = ({ offset, children }: IProps) => {
  return <Wrap offset={offset}>
    {children}
  </Wrap>
}

export default CommonTooltip