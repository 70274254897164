import React from "react"
import styled from "styled-components"

const Wrap = styled.span`
  border-radius: 30px;
  //background-color: rgba(0, 0, 0, 0.01);
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

interface IProps {
  callback: Function
}

const ClearIcon: React.FC<IProps> = (props) => {
  const { callback } = props
  return <Wrap onClick={() => callback()}>
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.792893 2.20711C0.402369 1.81658 0.402369 1.18342 0.792893 0.792893C1.18342 0.402369 1.81658 0.402369 2.20711 0.792893L6.5 5.08579L10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893C12.5976 1.18342 12.5976 1.81658 12.2071 2.20711L7.91421 6.5L12.2071 10.7929C12.5976 11.1834 12.5976 11.8166 12.2071 12.2071C11.8166 12.5976 11.1834 12.5976 10.7929 12.2071L6.5 7.91421L2.20711 12.2071C1.81658 12.5976 1.18342 12.5976 0.792894 12.2071C0.402369 11.8166 0.402369 11.1834 0.792894 10.7929L5.08579 6.5L0.792893 2.20711Z" fill="black"/>
    </svg>
  </Wrap>
}

export default ClearIcon