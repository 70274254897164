import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; 
  text-align: center;
  padding: 10px;
`;
const Card = styled.div<{ height?: string; margin?: string }>`
  width: 30%;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding-right: 20px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const Key = styled.p`
  font-size: 0.8rem;
  color: #333;
  margin: 0 0 0 0;
  padding: 20px 20px

`;

const Value = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
`;


export const CardStyles = {
    CardContainer,
    ContentBox,
    Card,
    Key,
    Value,
}