import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ValidationCrawlApiCallStatStyles as Styled } from '../ValidationCrawlApiCallStat.styles'

import useBasics from "hooks/useBasics";
import { ICommonCodeConvert } from "store/modules/types";
import { convertCommonCode } from "util/common";
import { ICrawlApiCallStatsParams, TCrawlApiCallStatsFilterSelect } from "../interface";
import ValidationCrawlApiCallStatFilterBox from "./ValidationCrawlApiCallStatFilterSelect";
import ValidationCrawlApiCallStatFilterFlatpicker from "./ValidationCrawlApiCallStatFilterFlatpicker";
import { validationCrawlApiCallStatFilterSelect } from "../constant";

interface IProps {
  params: ICrawlApiCallStatsParams;  
  setParams: Dispatch<SetStateAction<ICrawlApiCallStatsParams>>
}

const ValidationCrawlApiCallStatFilter: React.FC<IProps> = (props) => {
  const { params, setParams } = props
  const { 
    basics: { 
      commonCodeList, 
      markets 
    }, 
    requestMarkets 
  } = useBasics();

  useEffect(() => {
    if (!markets.size) {
      requestMarkets()
    }
  }, [])

  const serviceType = convertCommonCode(commonCodeList.service_type);
  const crawlApiCallType = convertCommonCode(commonCodeList.crawl_api_call_type);
  const marketList = markets.list;
 

  const serviceTypeOptios: ICommonCodeConvert[] = [
    { text: 'All', value: '' },
    ...serviceType.filter(s => s.value === 'IRANK' || s.value === 'REVIEWRAY'),
  ];

  const rootTypeOptions: ICommonCodeConvert[] = [
    { text: 'All', value: '' },
    ...crawlApiCallType,
  ];

  const apiTypeOptions: ICommonCodeConvert[] = [
    { text: 'All', value: '' },
    ...crawlApiCallType,
  ];

  const marketOptions: ICommonCodeConvert[] = [
    { text: 'All', value:'' },
    ...marketList.map(market => ({ text: market.name, value: market.marketId }))
  ]

  const isCrawlOptions: ICommonCodeConvert[] = [
    { text: 'All', value: '' },
    { text: 'true', value: 'true' },
    { text: 'false', value: 'false' },
  ];

  const filterOptions: { [ key in TCrawlApiCallStatsFilterSelect ] : ICommonCodeConvert[] } = {
    serviceType: serviceTypeOptios,
    rootType: rootTypeOptions,
    apiType: apiTypeOptions,
    marketId: marketOptions,
    isCrawl: isCrawlOptions
  }

  return <Styled.ValidationCrawlApiCallStatFilterWrap>
    {
      Object
        .entries(validationCrawlApiCallStatFilterSelect)
        .map(fs => {
          const title = fs[1];
          const field = fs[0] as TCrawlApiCallStatsFilterSelect
          return <ValidationCrawlApiCallStatFilterBox 
            key={`ValidationCrawlApiCallStatFilterBox-${field}`}
            value={params[field]} 
            options={filterOptions[field]} 
            title={title} 
            placeholder={"All"} 
            changeHandler={(e: any, data:any) => {
              setParams({
                ...params,
                [field]: data.value
              })
            }}
          />
        })
    }
    <Styled.ValidationCrawlApiCallStatFilterCommonBox>
      <Styled.Text>기간</Styled.Text>
      <Styled.FlatpickerWrap>
        <ValidationCrawlApiCallStatFilterFlatpicker 
          value={params.startDate} 
          fieldname={"startDate"} 
          params={params} 
          setParams={setParams} 
        />
        <Styled.Text>&nbsp;~&nbsp;</Styled.Text>
        <ValidationCrawlApiCallStatFilterFlatpicker 
          value={params.endDate} 
          fieldname={"endDate"} 
          params={params} 
          setParams={setParams} 
        />
      </Styled.FlatpickerWrap>
    </Styled.ValidationCrawlApiCallStatFilterCommonBox>
  </Styled.ValidationCrawlApiCallStatFilterWrap>
}

export default ValidationCrawlApiCallStatFilter