import { useDispatch, useSelector } from "react-redux";

// modules
import { StoreState } from "store/modules/types";
import { action } from "store/modules/crawlApiCallStats";

const useCrawlApiCallStat = () => {
  const dispatch = useDispatch();

  const crawlApiCallStats = useSelector(
    (state: StoreState) => state.crawlApiCallStats
  );

  const requestGetCrawlApiCallStats = (payload: any) => {
    dispatch(action.requestGetCrawlApiCallStats(payload));
  };

  return {
    crawlApiCallStats,
    requestGetCrawlApiCallStats,
  };
};

export default useCrawlApiCallStat;
