import { CircularProgress } from "@material-ui/core"
import styled, { css } from "styled-components"
import React from "react"

const Wrap = styled.div`
  position: fixed;
  width: 100vw;
 height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  

  display: flex;
  justify-content: center;
  align-items: center;
`

const FullScreenLoader = () => {

  return <Wrap>
    <CircularProgress size={50} />
  </Wrap>
}

export default FullScreenLoader