import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  align-items: start;  // 날짜와 카드의 수직 중앙 정렬
  margin-bottom: 20px;  // 전체 컨테이너의 하단 여백
  align-items: center;
`;

const Card = styled.div<{ height?: string; margin?: string }>`
  width: 350px;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding-right: 20px;

`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; 
  text-align: center;
  padding: 10px;
`;

const Key = styled.p`
  font-size: 0.8rem;
  color: #333;
  margin: 0 0 0 0;
  padding: 20px 20px;

`;

const Value = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
//   margin-bottom: 20px;
  
`;
const LinkText = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
`;

const LinkButton = styled.a`
background-color: #e6e6e6;
color: black;
border: 1px solid rgba(0, 0, 0, 0.12);
padding: 10px 30px;
border-radius: 8px;
cursor: pointer;
font-size: 15px;
width: 200px; 
height: 35px; 
display: flex;
align-items: center;
justify-content: center;
white-space: nowrap; /* 텍스트를 한 줄로 표시 */
margin-right: 70px;
&:hover {
  background-color: #BAD3E2;
}

&:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
`;
const recollectAndLinkWrapper = styled.div`
  display:flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  align-items: baseline;
`;

export const TrendNewsStyles = {Container, Card, ContentBox, Key, Value, ButtonContainer, LinkButton, LinkText, Wrapper, recollectAndLinkWrapper, Text};