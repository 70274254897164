import React, { useState } from 'react';

import TableList, { ITableListProps, ITableListButton, IFindTextBox, IPagination } from 'components/common/table/TableList';
import { ICrawlApiCallStatsParams, TValidationCrawlApiCallStatLabelKey, TValidationCrawlApiCallStatSort } from './interface';
import { Dispatch, SetStateAction } from 'react';
import useCrawlApiCallStat from 'hooks/useCrawlApiCallStat';
import ValidationCrawlApiCallStatListItem from './ValidationCrawlApiCallStatListItem';
import { ICrawlApiCallStat, ICrawlApiCallStats } from 'store/modules/types';
import { SORT_TYPE } from 'components/common/button/Sort';
import { initValidationCrawlApiCallStatSort, validationCrawlApiCallStatLabels } from './constant';

interface IProps {
  sort: TValidationCrawlApiCallStatSort;
  setSort: Dispatch<SetStateAction<TValidationCrawlApiCallStatSort>>
  params: ICrawlApiCallStatsParams;
  setParams: Dispatch<SetStateAction<ICrawlApiCallStatsParams>>
  onRequest: (params?: ICrawlApiCallStatsParams) => void
}

const ValidationCrawlApiCallStatList: React.FC<IProps> = (props) => {
  const { 
    sort,
    setSort,
    params, 
    setParams, 
    onRequest 
  } = props

  
  const {
    crawlApiCallStats : {
      crawlApiCallStats
    }
  } = useCrawlApiCallStat()

  const onChangePage = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, getData: any) => {    
    setParams({
      ...params, 
      [getData.name]: getData.activePage
    });
  }

  const sortHandler = (
    type: SORT_TYPE, 
    sortKey: TValidationCrawlApiCallStatLabelKey, 
    sortType: SORT_TYPE | ''
  ) => {

    const getSort = (sortType === '' || type !== sortType)
      ? `${sortKey}_${type}` 
      : ''
    
    if (getSort) {
      setSort({
        ...initValidationCrawlApiCallStatSort,
        [sortKey] : type
      })
    } else {
      setSort(initValidationCrawlApiCallStatSort)
    }

    setParams({
      ...params,
      orderBy: getSort
    })
  }

  const headers = validationCrawlApiCallStatLabels.map(label => label.label); 
  const headerSort = validationCrawlApiCallStatLabels.map(label => ({ 
    header: label.label, 
    key: label.key, 
    state: sort[label.key], 
    handler: (type: SORT_TYPE) => {
      sortHandler(type, label.key, sort[label.key])
    }
  }))

  const pagination : IPagination = {
    name: 'page',
    activePage: params.page,
    totalPage: crawlApiCallStats.crawlApiCallStats.page.totalPages,
    onChange: onChangePage, 
  };

  const tableListProps: ITableListProps = {
    width: '100%',
    title: '리스트',
    size: 'large',
    color: 'black',
    buttons: undefined,
    headers,
    headerSort: headerSort,
    pagination,
    count: crawlApiCallStats.crawlApiCallStats.page.totalElements,
    refresh: () => onRequest(params),
    
  };

  const crawlApiCallStatItems = crawlApiCallStats.crawlApiCallStats.list.map((item:ICrawlApiCallStat, i:number) => (
    <ValidationCrawlApiCallStatListItem
      key={i}
      idx={i}
      item={item}
    />
  ));

  return <TableList {...tableListProps}>        
    {crawlApiCallStatItems}        
  </TableList>

}

export default ValidationCrawlApiCallStatList