import React, { useEffect } from 'react';

import { IMenuParams } from 'components/common/constants';

// Components
import Dashboard from 'components/dashboard';
import Users from 'components/users';
import UseTerms from 'components/userterms';
import Payment from 'components/payment';

// import NoticeList from 'components/notice/NoticeList';
import Notice from 'components/notice';
import Faq from 'components/faq';
import Qna from 'components/qna';
import SalonOn from 'components/salonOn';
import Market from 'components/market';

import Notice2 from 'components/notice2';
import Faq2 from 'components/faq2';
import EmailTemplate from 'components/emailTemplate';
import Banner from 'components/banner';

import UserLogs from 'components/userLogs';

// hooks
import useBasics from 'hooks/useBasics';
import Guide from 'components/guide';
import Index from 'components/event/index';
import UserSales from 'components/usersales';
import Email from 'components/email';
import Demo from 'components/demo';
import Validation from 'components/validation';
import ValidationReview from 'components/validationReview';
import ValidationCrawlApiCallStat from 'components/validationCrawlApiCallStat';

interface IMainProps {
  selectedMenu?: IMenuParams;
}

const Main: React.SFC<IMainProps> = (props) => {

  const { selectedMenu } = props;
  
  const { requestAllCommonCode } = useBasics();

  useEffect(() => requestAllCommonCode({ async: true }), []);    

  const content = () => {

    let comp = null;

    switch (selectedMenu?.key) {
      case 'dashboard':
        comp = <Dashboard />;
        break;
      case 'users':
        comp = <Users />
        break;
      case 'notice':
        comp = <Notice />
        break;
      case 'qna':
        comp = <Qna />
        break;        
      case 'faq':
        comp = <Faq />
        break;
      case 'terms of service':
        comp = <UseTerms />
        break; 
      case 'payment':
        comp = <Payment />
        break;
      case 'guide':
        comp = <Guide />
        break;   
      case 'event':
        comp = <Index />
        break;
      case 'salon on':
        comp = <SalonOn />
        break;
      case 'market':
        comp = <Market />
        break;
      case 'notice2':
        comp = <Notice2 />
        break;
      case 'faq2':
        comp = <Faq2 />
        break;
      case 'email':
        comp = <Email />
        break;
      case 'email template':
        comp = <EmailTemplate />
        break;
      case 'banner':
        comp = <Banner />
        break;
      case 'user sales':
        comp = <UserSales />
        break;
      case 'user logs':
        comp = <UserLogs />
        break;
      case 'demo':
        comp = <Demo />
        break;

      case 'validation-irank':
        comp = <Validation />
        break;
      case 'validation-review':
        comp = <ValidationReview />
        break;

      case 'validation-crawl-call-stat':
        comp = <ValidationCrawlApiCallStat />
        break;
    }

    // return <Faq />
    return comp;
  }

  return (
    <article id='content-div'>
      {content()}
    </article>
  );
}

export default Main;