
import React, { Dispatch, SetStateAction } from 'react'
import { DropdownProps, Select } from "semantic-ui-react";
import { ValidationCrawlApiCallStatStyles as Styled } from '../ValidationCrawlApiCallStat.styles'
import { ICrawlApiCallStatsParams } from '../interface';
import { ICommonCodeConvert } from 'store/modules/types';

interface IProps {
  value: boolean | number | string | (boolean | number | string)[]
  options: ICommonCodeConvert[]
  title: string
  placeholder: string
  changeHandler: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
}

const ValidationCrawlApiCallStatFilterBox: React.FC<IProps> = (props) => {
  const { 
    value, 
    options, 
    title, 
    placeholder,
    changeHandler
  } = props

  return <Styled.ValidationCrawlApiCallStatFilterCommonBox>
    <Styled.Text>{title}</Styled.Text>
    <Select 
      placeholder={placeholder}
      options={options} 
      value={value} 
      onChange={changeHandler}
    />
  </Styled.ValidationCrawlApiCallStatFilterCommonBox>
}

export default ValidationCrawlApiCallStatFilterBox