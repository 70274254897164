import React, { useState, useEffect } from "react";
import useTodayBest from "hooks/useTodayBest";
import { CountTodayBest, TodayBestState } from "store/modules/types";
import { TodayBestStyles as Styled } from "../styles/TodayBestStyle/TodayBest.style";
import useTodayBestPerMarket from "hooks/useTodayBestPerMarket";
import TodayBestTable from "./TodayBestTable";
import {
  RecollectButtonContainer,
  Title as MarketName,
  CustomizedButton,
} from "../styles/CommonStyle/Common.style";
import DayOfWeek from "../DayOfWeek";
interface IProps {
  searchDate: string;
}

interface MarketInfo {
  marketId: string;
  marketName: string;
  countList: CountTodayBest[];
  todayList: TodayBestState[];
  selectedIndex: number;
  selectedDate: string;
}

const TodayBest: React.FC<IProps> = (props) => {
  const { searchDate } = props;
  const { bestProduct, requestBestProduct } = useTodayBest();
  const { bestData } = bestProduct;
  const { bestProductPerMarket, requestBestProductPerMarket } =
    useTodayBestPerMarket();
  const { marketData } = bestProductPerMarket;

  const marketInfo = [
    { marketId: "mall0148", marketName: "스마트스토어" },
    { marketId: "mall0025", marketName: "11번가" },
    { marketId: "mall0010", marketName: "G마켓" },
    { marketId: "mall0003", marketName: "옥션" },
  ];
  const [marketInfos, setMarketInfos] = useState<MarketInfo[]>(
    marketInfo.map((market) => ({
      ...market,
      countList: [],
      todayList: [],
      selectedIndex: 0,
      selectedDate: searchDate,
    }))
  );

  useEffect(() => {
    requestBestProduct({ queryParameters: { searchDt: searchDate } });
  }, [searchDate]);

  useEffect(() => {
    const updatedMarketInfos = marketInfos.map((item) => {
      const newMarketInfos = bestData.list.find(
        (e) => e.marketId === item.marketId
      );
      return {
        ...item,
        countList: newMarketInfos ? newMarketInfos.count : [],
        todayList: newMarketInfos ? newMarketInfos.todayBests : [],
        selectedIndex: 0,
        selectedDate: searchDate,
      };
    });
    setMarketInfos(updatedMarketInfos);
  }, [bestData]);

  useEffect(() => {
    const updatedMarketInfos = marketInfos.map((item) => {
      if (item.marketId === marketData.marketId) {
        return {
          ...item,
          todayList: marketData.todayBestDtoList,
        };
      }
      return {
        ...item,
      };
    });
    setMarketInfos(updatedMarketInfos);
  }, [bestProductPerMarket]);

  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString.split("-");
    const shortYear = year.slice(2); // 연도의 마지막 두 자리
    return `${shortYear}.${month}.${day}`;
  };

  const onMisCollectedList = (todayCount: CountTodayBest, index: number) => {
    const updatedMarketInfos = marketInfos.map((item) => {
      if (item.marketId === todayCount.marketId) {
        return {
          ...item,
          selectedIndex: index,
          selectedDate: todayCount.regDt,
        };
      }
      return item;
    });

    setMarketInfos(updatedMarketInfos);

    requestBestProductPerMarket({
      queryParameters: {
        searchDt: todayCount.regDt,
        marketId: todayCount.marketId,
      },
    });
  };

  return (
    <Styled.Container>
      {marketInfos.map((marketItem, index) => {
        return (
          <Styled.MarketContainer>
            <RecollectButtonContainer>
              <MarketName>{marketItem.marketName}</MarketName>
            </RecollectButtonContainer>
            <Styled.Section key={index}>
              {marketItem.countList.length > 0 ? (
                marketItem.countList.map(
                  (todayCount, index) =>
                    todayCount && (
                      <Styled.CardContainer key={index}>
                        <Styled.Text>
                          {formatDate(todayCount.regDt)}
                          &nbsp;
                          <DayOfWeek
                            isFullDate={false}
                            dateString={todayCount.regDt}
                          />
                        </Styled.Text>

                        <Styled.Card
                          key={index}
                          onClick={() => {
                            onMisCollectedList(todayCount, index);
                          }}
                          selected={index === marketItem.selectedIndex}
                        >
                          <Styled.ContentBox>
                            <Styled.Title>수집완료</Styled.Title>
                            <Styled.Value>{todayCount.complete}</Styled.Value>
                          </Styled.ContentBox>
                          <Styled.ContentBox>
                            <Styled.Title>미 수집</Styled.Title>
                            <Styled.Value>{todayCount.fail}</Styled.Value>
                          </Styled.ContentBox>
                        </Styled.Card>
                      </Styled.CardContainer>
                    )
                )
              ) : (
                <p>
                  {searchDate} 일에 {marketItem.marketName}에 대한 데이터가
                  없습니다.
                </p>
              )}
              {marketItem.countList.length > 0 && (
                <TodayBestTable
                  todayBests={marketItem.todayList}
                  clickedDate={marketItem.selectedDate}
                  marketName={marketItem.marketName}
                  // ref= {tableRef}
                ></TodayBestTable>
              )}
            </Styled.Section>
          </Styled.MarketContainer>
        );
      })}
    </Styled.Container>
  );
};

export default TodayBest;
