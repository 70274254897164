import { useCallback, useEffect, useRef, useState } from "react"

const useHover = <T extends HTMLElement = HTMLElement>() => {
  const [ isHover, setIsHover ] = useState<boolean>(false)
  const ref = useRef<T | null>(null);

  const mouseEnterHandler = useCallback(() => setIsHover(true), []);
  const mouseLeaveHandler = useCallback(() => setIsHover(false), []);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', mouseEnterHandler)
      node.addEventListener('mouseleave', mouseLeaveHandler)
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseenter', mouseEnterHandler)
        node.removeEventListener('mouseleave', mouseLeaveHandler)
      }
    }
  }, [])

  return { ref, isHover }
}

export default useHover