// API default actions
export const EPIC_SUCCESS = "epic/SUCCESS";
export const EPIC_FAIL = "epic/FAIL";
export const EPIC_CANCEL = "epic/CANCEL";

// Basics actions
export const LOGIN = "basic/LOGIN";
export const LOGIN_SUCCEEDED = "basic/LOGIN_SUCCEEDED";
export const LOGIN_FAILED = "basic/LOGIN_FAILED";
export const LOGOUT = "basic/LOGOUT";
export const TOKEN_REFRESH = "basic/TOKEN_REFRESH";
export const SUCCEEDED_TOKEN_REFRESH = "basic/SUCCEEDED_TOKEN_REFRESH";
export const IS_AUTHENTICATED = "basic/IS_AUTHENTICATED";
export const UPDATE_USER_INFO = "basic/UPDATE_USER_INFO";
export const SET_MENU = "basic/SET_MENU";
export const GET_ALL_COMMON_CODE = "basic/GET_ALL_COMMON_CODE";
export const SUCCEEDED_GET_ALL_COMMON_CODE = "basic/SUCCEEDED_GET_ALL_COMMON_CODE";
export const FAILED_GET_ALL_COMMON_CODE = "basic/FAILED_GET_ALL_COMMON_CODE";

// 마켓 정보 조회 2024-09-27 hcjang
export const GET_MARKETS = "basic/GET_MARKETS";
export const SUCCEEDED_GET_MARKETS = "basic/SUCCEEDED_GET_MARKETS";
export const FAILED_GET_MARKETS = "basic/FAILED_GET_MARKETS";

// Users actions
export const GET_USER_BY_UID = "user/GET_USER_BY_UID";
export const SUCCEEDED_GET_USER_BY_UID = "user/SUCCEEDED_GET_USER_BY_UID";
export const FAILED_GET_USER_BY_UID = "user/FAILED_GET_USER_BY_UID";

export const GET_USER_LIST = "user/GET_USER_LIST";
export const FULFILLED_USER_LIST = "user/FULFILLED_USER_LIST";
export const FAILED_USER_LIST = "user/FAILED_USER_LIST";

export const PATCH_USER_ROLES = "user/PATCH_USER_ROLES";
export const SUCCEEDED_PATCH_USER_ROLES = "user/SUCCEEDED_PATCH_USER_ROLES";
export const FAILED_PATCH_USER_ROLES = "user/FAILED_PATCH_USER_ROLES";

export const GET_REVIEWRAY_PRODUCTS = "user/GET_REVIEWRAY_PRODUCTS";
export const SUCCEEDED_GET_REVIEWRAY_PRODUCTS = "user/SUCCEEDED_GET_REVIEWRAY_PRODUCTS";
export const FAILED_GET_REVIEWRAY_PRODUCTS = "user/FAILED_GET_REVIEWRAY_PRODUCTS";

export const UPDATE_USER = "user/UPDATE_USER";
export const SUCCEEDED_UPDATE_USER = "user/SUCCEEDED_UPDATE_USER";
export const FAILED_UPDATE_USER = "user/FAILED_UPDATE_USER";

export const DELETE_USER = "user/DELETE_USER";
export const SUCCEEDED_DELETE_USER = "user/SUCCEEDED_DELETE_USER";
export const FAILED_DELETE_USER = "user/FAILED_DELETE_USER";

export const GET_USER = "user/GET_USER";
export const FULFILLED_USER = "user/FULFILLED_USER";
export const FAILED_USER = "user/FAILED_USER";

export const GET_USER_MARKET_LIST = "user/GET_USER_MARKET_LIST";
export const FULFILLED_USER_MARKET_LIST = "user/FULFILLED_USER_MARKET_LIST";
export const FAILED_USER_MARKET_LIST = "user/FAILED_USER_MARKET_LIST";

export const USER_PASSWORD_UPDATE = "user/USER_PASSWORD_UPDATE";
export const USER_PASSWORD_UPDATE_SUCCEEDED = "basic/USER_PASSWORD_UPDATE_SUCCEEDED";
export const USER_PASSWORD_UPDATE_FAILED = "basic/USER_PASSWORD_UPDATE_FAILED";

export const USER_PASSWORD_RESET = "user/USER_PASSWORD_RESET";
export const USER_PASSWORD_RESET_SUCCEEDED = "basic/USER_PASSWORD_RESET_SUCCEEDED";
export const USER_PASSWORD_RESET_FAILED = "basic/USER_PASSWORD_RESET_FAILED";

export const USER_ID_CHECK = "basic/USER_ID_CHECK";
export const USER_ID_CHECK_SUCCEEDED = "basic/USER_ID_CHECK_SUCCEEDED";
export const USER_ID_CHECK_FAILED = "basic/USER_ID_CHECK_FAILED";

export const USER_EMAIL_CHECK = "basic/USER_EMAIL_CHECK";
export const USER_EMAIL_CHECK_SUCCEEDED = "basic/USER_EMAIL_CHECK_SUCCEEDED";
export const USER_EMAIL_CHECK_FAILED = "basic/USER_EMAIL_CHECK_FAILED";

export const USER_INSERT = "basic/USER_INSERT";
export const USER_INSERT_SUCCEEDED = "basic/USER_INSERT_SUCCEEDED";
export const USER_INSERT_FAILED = "basic/USER_INSERT_FAILED";

export const SECRET_LOGIN = "user/SECRET_LOGIN";
export const SECRET_LOGIN_SUCCEEDED = "user/SECRET_LOGIN_SUCCEEDED";
export const SECRET_LOGIN_FAILED = "user/SECRET_LOGIN_FAILED";

export const GET_USER_ANALYZE_STATUS = "user/GET_USER_ANALYZE_STATUS";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS";
export const FAILED_GET_USER_ANALYZE_STATUS = "user/FAILED_GET_USER_ANALYZE_STATUS";

export const GET_USER_ANALYZE_STATUS_COMPARE_MONITORING = "user/GET_USER_ANALYZE_STATUS_COMPARE_MONITORING";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING";
export const FAILED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING = "user/FAILED_GET_USER_ANALYZE_STATUS_COMPARE_MONITORING";

export const GET_USER_ANALYZE_STATUS_COMPREHENSIVE = "user/GET_USER_ANALYZE_STATUS_COMPREHENSIVE";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE";
export const FAILED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE = "user/FAILED_GET_USER_ANALYZE_STATUS_COMPREHENSIVE";

export const GET_USER_ANALYZE_STATUS_KEYWORD = "user/GET_USER_ANALYZE_STATUS_KEYWORD";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS_KEYWORD = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS_KEYWORD";
export const FAILED_GET_USER_ANALYZE_STATUS_KEYWORD = "user/FAILED_GET_USER_ANALYZE_STATUS_KEYWORD";

export const GET_USER_ANALYZE_STATUS_PRODUCTS = "user/GET_USER_ANALYZE_STATUS_PRODUCTS";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS_PRODUCTS = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS_PRODUCTS";
export const FAILED_GET_USER_ANALYZE_STATUS_PRODUCTS = "user/FAILED_GET_USER_ANALYZE_STATUS_PRODUCTS";

export const GET_USER_ANALYZE_STATUS_TRACE_MONITORING = "user/GET_USER_ANALYZE_STATUS_TRACE_MONITORING";
export const SUCCEEDED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING = "user/SUCCEEDED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING";
export const FAILED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING = "user/FAILED_GET_USER_ANALYZE_STATUS_TRACE_MONITORING";

// /api/v1/admin/userSales 판매정보 - 사용자 선택시
export const GET_USER_SALES_INFO_SUMMARY = "user/GET_USER_SALES_INFO_SUMMARY";
export const FULFILLED_USER_SALES_INFO_SUMMARY = "user/FULFILLED_USER_SALES_INFO_SUMMARY";
export const FAILED_USER_SALES_INFO_SUMMARY = "user/FAILED_USER_SALES_INFO_SUMMARY";

// /api/v1/admin/userSalesDetail 판매정보 - 사용자 선택 후 날짜 선택시
export const GET_USER_SALES_INFO_DETAIL = "user/GET_USER_SALES_INFO_DETAIL";
export const FULFILLED_USER_SALES_INFO_DETAIL = "user/FULFILLED_USER_SALES_INFO_DETAIL";
export const FAILED_USER_SALES_INFO_DETAIL = "user/FAILED_USER_SALES_INFO_DETAIL";

// user Sales
export const GET_USER_SALES = "user/GET_USER_SALES";
export const SUCCEDED_GET_USER_SALES = "user/SUCCEDE_GET_USER_SALES";
export const FAILED_GET_USER_SALES = "user/FAILED_GET_USER_SALES"

// /api/v1/admin/userLoginLog 회원로그
export const GET_USER_LOGS = "user/GET_USER_LOGS";
export const FULFILLED_USER_LOGS = "user/FULFILLED_USER_LOGS";
export const FAILED_USER_LOGS = "user/FAILED_USER_LOGS";


// FAQ actions
export const FAQ_LIST = "board/FAQ_LIST";
export const FULFILLED_FAQ_LIST = "board/FULFILLED_FAQ_LIST";
export const FAILED_FAQ_LIST = "board/FAILED_FAQ_LIST";

export const GET_FAQ = "board/GET_FAQ";
export const FULFILLED_GET_FAQ = "board/FULFILLED_GET_FAQ";
export const FAILED_GET_FAQ = "board/FAILED_GET_FAQ";

export const FAQ_CREATE = "board/FAQ_CREATE";
export const SUCCEEDED_FAQ_CREATE = "board/SUCCEEDED_FAQ_CREATE";
export const FAILED_FAQ_CREATE = "board/FAILED_FAQ_CREATE";

export const FAQ_MODIFY = "board/FAQ_MODIFY";
export const SUCCEEDED_FAQ_MODIFY = "board/SUCCEEDED_FAQ_MODIFY";
export const FAILED_FAQ_MODIFY = "board/FAILED_FAQ_MODIFY";

export const FAQ_DELETE = "board/FAQ_DELETE";
export const SUCCEEDED_FAQ_DELETE = "board/FAILED_FAQ_DELETE";
export const FAILED_FAQ_DELETE = "board/FAILED_FAQ_DELETE";

// FAQ
export const GET_FAQ_LIST2 = "faq/GET_FAQ_LIST";
export const FULFILLED_FAQ_LIST2 = "faq/FULFILLED_FAQ_LIST";
export const FAILED_FAQ_LIST2 = "faq/FAILED_FAQ_LIST";

// QNA actions
export const GET_QNA_CODE = "board/GET_QNA_CODE";
export const SUCCEEDED_QNA_CODE = "board/SUCCEEDED_QNA_CODE";
export const FAILED_QNA_CODE = "board/FAILED_QNA_CODE";
export const GET_QNA_LIST = "board/GET_QNA_LIST";
export const FULFILLED_QNA_LIST = "board/FULFILLED_QNA_LIST";
export const FAILED_QNA_LIST = "board/FAILED_QNA_LIST";
export const ADMIN_GET_QNA = "board/ADMIN_GET_QNA";
export const FULFILLED_ADMIN_GET_QNA = "board/SUCCEEDED_ADMIN_GET_QNA";
export const FAILED_ADMIN_GET_QNA = "board/FAILED_ADMIN_GET_QNA";
export const CREATE_QNA = "board/CREATE_QNA";
export const SUCCEEDED_CREATE_QNA = "board/SUCCEEDED_CREATE_QNA";
export const FAILED_CREATE_QNA = "board/FAILED_CREATE_QNA";
export const MODIFY_QNA = "board/MODIFY_QNA";
export const SUCCEEDED_MODIFY_QNA = "board/SUCCEEDED_MODIFY_QNA";
export const FAILED_MODIFY_QNA = "board/FAILED_MODIFY_QNA";
export const DELETE_QNA = "board/DELETE_QNA";
export const SUCCEEDED_DELETE_QNA = "board/SUCCEEDED_DELETE_QNA";
export const FAILED_DELETE_QNA = "board/FAILED_DELETE_QNA";
export const STATUS_UPDATE = "board/STATUS_UPDATE";
export const SUCCEEDED_STATUS_UPDATE = "board/SUCCEEDED_STATUS_UPDATE";
export const FAILED_STATUS_UPDATE = "board/FAILED_STATUS_UPDATE";


// Use of Terms actions
export const GET_TOS_LIST = "user/GET_TOS_LIST";
export const FULFILLED_TOS_LIST = "user/FULFILLED_TOS_LIST";
export const FAILED_TOS_LIST = "user/FAILED_TOS_LIST";

// Crawling actions
export const GET_CRAWLING_SUMMARY = "crawling/GET_CRAWLING_SUMMARY";

// Notice
export const GET_NOTICE_LIST = "notice/GET_NOTICE_LIST";
export const FULFILLED_NOTICE_LIST = "notice/FULFILLED_NOTICE_LIST";
export const FAILED_NOTICE_LIST = "notice/FAILED_NOTICE_LIST";
export const GET_NOTICE = "notice/GET_NOTICE";
export const SUCCEEDED_GET_NOTICE = "notice/SUCCEEDED_GET_NOTICE";
export const FAILED_GET_NOTICE = "notice/FAILED_GET_NOTICE";


// writeNotice
export const GET_WRITE_NOTICE = "notice/GET_WRITE_NOTICE";
export const FULFILLED_WRITE_NOTICE = "notice/FULFILLED_WRITE_NOTICE";
export const FAILED_WRITE_NOTICE = "notice/FAILED_WRITE_NOTICE";

//DeleteNotice
export const DELETE_NOTICE = "notice/DELETE_NOTICE";
export const SUCCEEDED_DELETE_NOTICE = "notice/SUCCEEDED_DELETE_NOTICE";
export const FAILED_DELETE_NOTICE = "notice/FAILED_DELETE_NOTICE";

//UpdateNotice
export const UPDATE_NOTICE = "notice/UPDATE_NOTICE";
export const SUCCEEDED_UPDATE_NOTICE = "notice/SUCCEEDED_UPDATE_NOTICE";
export const FAILED_UPDATE_NOTICE = "notice/FAILED_UPDATE_NOTICE";

//DetailNotice
export const GET_DETAIL_NOTICE = "notice/GET_DETAIL_NOTICE";
export const SUCCEEDED_DETAIL_NOTICE = "notice/SUCCEEDED_DETAIL_NOTICE";
export const FAILED_DETAIL_NOTICE = "notice/FAILED_DETAIL_NOTICE";
// Payment actions
// Read(List)
export const GET_PAYMENT_LIST = 'payment/GET_PAYMENT_LIST';
export const FULFILLED_PAYMENT_LIST = 'payment/FULFILLED_PAYMENT_LIST';
export const FAILED_PAYMENT_LIST = 'payment/FAILED_PAYMENT_LIST';
// Read(detail)
export const GET_PAYMENT = 'payment/GET_PAYMENT';
export const FULFILLED_PAYMENT = 'payment/FULFILLED_PAYMENT';
export const FAILED_PAYMENT = 'payment/FAILED_PAYMENT';
// Delete
export const DELETE_PAYMENT = "payment/DELETE_PAYMENT";
export const SUCCEEDED_DELETE_PAYMENT = "payment/SUCCEEDED_DELETE_PAYMENT";
export const FAILED_DELETE_PAYMENT = "payment/FAILED_DELETE_PAYMENT";
// Insert
export const INSERT_PAYMENT = "payment/INSERT_PAYMENT";
export const SUCCEEDED_INSERT_PAYMENT = "payment/SUCCEEDED_INSERT_PAYMENT";
export const FAILED_INSERT_PAYMENT = "payment/FAILED_INSERT_PAYMENT";
// Update
export const MODIFY_PAYMENT = "payment/MODIFY_PAYMENT";
export const SUCCEEDED_MODIFY_PAYMENT = "payment/SUCCEEDED_MODIFY_PAYMENT";
export const FAILED_MODIFY_PAYMENT = "payment/FAILED_MODIFY_PAYMENT";

// Common code actions
export const GET_CODE_LIST_ALL = "common/GET_CODE_LIST_ALL";
export const FULFILLED_CODE_LIST_ALL = "common/FULFILLED_CODE_LIST_ALL";
export const FAILED_CODE_LIST_ALL = "common/FAILED_CODE_LIST_ALL";

// Guide Actions
export const GET_GUIDE_LIST = "guide/GET_GUIDE_LIST";
export const FULFILLED_GUIDE_LIST = "guide/FULFILLED_GUIDE_LIST";
export const FAILED_GUIDE_LIST = "guide/FAILED_GUIDE_LIST";
export const INSERT_GUIDE = "guide/INSERT_GUIDE";
export const SUCCEEDED_INSERT_GUIDE = "guide/SUCCEEDED_INSERT_GUIDE";
export const FAILED_INSERT_GUIDE = "guide/FAILED_INSERT_GUIDE";
export const DELETE_GUIDE = "guide/DELETE_GUIDE";
export const SUCCEEDED_DELETE_GUIDE = "guide/SUCCEEDED_DELETE_GUIDE";
export const FAILED_DELETE_GUIDE = "guide/FAILED_DELETE_GUIDE";
export const MODIFY_GUIDE = "guide/MODIFY_GUIDE";
export const SUCCEEDED_MODIFY_GUIDE = "guide/SUCCEEDED_MODIFY_GUIDE";
export const FAILED_MODIFY_GUIDE = "guide/FAILED_MODIFY_GUIDE";


//EVENTLIST
export const GET_EVENT_LIST = "event/GET_EVENT_LIST";
export const FULFILLED_EVENT_LIST = "event/FULFILLED_EVENT_LIST";
export const FAILED_EVENT_LIST = "event/FAILED_EVENT_LIST";
 
//EVENTDETAIL
export const DELETE_EVENT = "event/DELETE_EVENT";
export const SUCCEEDED_DELETE_EVENT = "event/SUCCEEDED_DELETE_EVENT";
export const FAILED_DELETE_EVENT = "event/FAILED_DELETE_EVENT";
 

export const GET_WRITE_EVENT = "notice/GET_WRITE_EVENT";
export const FULFILLED_WRITE_EVENT = "notice/FULFILLED_WRITE_EVENT";
export const FAILED_WRITE_EVENT = "notice/FAILED_WRITE_EVENT";


export const GET_EVENT_DETAIL = "event/GET_EVENT_DETAIL";
export const FULFILLED_EVENT_DETAIL = "event/FULFILLED_EVENT_DETAIL";
export const FAILED_EVENT_DETAIL = "event/FAILED_EVENT_DETAIL";

export const GET_MODIFY_EVENT = "event/GET_MODIFY_EVENT";
export const FULFILLED_MODIFY_EVENT = "event/FULFILLED_MODIFY_EVENT";
export const FAILED_MODIFY_EVENT = "event/FAILED_MODIFY_EVENT";

 
// Salon On
export const GET_DSON_LIST = "board/GET_DSON_LIST";
export const FULFILLED_DSON_LIST = "board/FULFILLED_DSON_LIST";
export const FAILED_GET_DSON_LIST = "board/FAILED_GET_DSON_LIST";

export const GET_DSON_SELECT_LIST = "board/GET_DSON_SELECT_LIST";
export const FAILED_GET_DSON_SELECT_LIST = "board/FAILED_GET_DSON_SELECT_LIST";

export const GET_DSON_DETAIL = "board/GET_DSON_DETAIL";
export const SUCCEEDED_GET_DSON_DETAIL = "board/SUCCEEDED_GET_DSON_DETAIL";
export const FAILED_GET_DSON_DETAIL = "board/FAILED_GET_DSON_DETAIL";

export const CREATE_DSON = "board/CREATE_DSON";
export const SUCCEEDED_CREATE_DSON = "board/SUCCEEDED_CREATE_DSON";
export const FAILED_CREATE_DSON = "board/FAILED_CREATE_DSON";

export const MODIFY_DSON = "board/MODIFY_DSON";
export const SUCCEEDED_MODIFY_DSON = "board/SUCCEEDED_MODIFY_DSON";
export const FAILED_MODIFY_DSON = "board/FAILED_MODIFY_DSON";

export const DELETE_DSON = "board/DELETE_DSON";
export const SUCCEEDED_DELETE_DSON = "board/SUCCEEDED_DELETE_DSON";
export const FAILED_DELETE_DSON = "board/FAILED_DELETE_DSON";

// Salon On Hash Tag
export const GET_TAG_LIST = "board/GET_TAG_LIST";
export const FULFILLED_TAG_LIST = "board/FULFILLED_TAG_LIST";
export const FAILED_GET_TAG_LIST = "board/FAILED_GET_TAG_LIST";

export const CREATE_TAG = "board/CREATE_TAG";
export const SUCCEEDED_CREATE_TAG = "board/SUCCEEDED_CREATE_TAG";
export const FAILED_CREATE_TAG = "board/FAILED_CREATE_TAG";

export const MODIFY_TAG = "board/MODIFY_TAG";
export const SUCCEEDED_MODIFY_TAG = "board/SUCCEEDED_MODIFY_TAG";
export const FAILED_MODIFY_TAG = "board/FAILED_MODIFY_TAG";

export const DELETE_TAG = "board/DELETE_TAG";
export const SUCCEEDED_DELETE_TAG = "board/SUCCEEDED_DELETE_TAG";
export const FAILED_DELETE_TAG = "board/FAILED_DELETE_TAG";

export const  GET_CODE_LIST = "code/GET_CODE_LIST";
export const  SUCCEEDED_GET_CODE_LIST = "code/SUCCEEDED_GET_CODE_LIST";
export const  FAILED_GET_CODE_LIST = "code/FAILED_GET_CODE_LIST";

// Market 
export const GET_MARKET_LIST = "market/GET_MARKET_LIST";
export const FULFILLED_MARKET_LIST = "market/FULFILLED_MARKET_LIST";
export const FAILED_MARKET_LIST = "market/FAILED_MARKET_LIST";

export const GET_MARKET = "market/GET_MARKET";
export const SUCCEEDED_GET_MARKET = "market/SUCCEEDED_MARKET";
export const FAILED_GET_MARKET = "market/FAILED_MARKET";

export const CREATE_MARKET = "market/CREATE_MARKET";
export const SUCCEEDED_CREATE_MARKET = "market/SUCCEEDED_CREATE_MARKET";
export const FAILED_CREATE_MARKET = "market/FAILED_CREATE_MARKET";

export const DELETE_MARKET = "market/DELETE_MARKET";
export const SUCCEEDED_DELETE_MARKET = "market/SUCCEEDED_DELETE_MARKET";
export const FAILED_DELETE_MARKET = "market/FAILED_DELETE_MARKET";

export const MODIFY_MARKET = "market/MODIFY_MARKET";
export const SUCCEEDED_MODIFY_MARKET = "market/SUCCEEDED_MODIFY_MARKET";
export const FAILED_MODIFY_MARKET = "market/FAILED_MODIFY_MARKET";

export const GET_SALES_INFO = "sales/GET_SALES_INFO";
export const FULFILLED_SALES_INFO = "sales/FULFILLED_SALES_INFO";
export const FAILED_SALES_INFO = "sales/FAILED_SALES_INFO";

// Email
export const SEND_EMAIL_BULK_BY_EMAILS = "email/SEND_EMAIL_BULK_BY_EMAILS";
export const SUCCEEDED_SEND_EMAIL_BULK_BY_EMAILS = "email/SUCCEEDED_SEND_EMAIL_BULK_BY_EMAILS";
export const FAILED_SEND_EMAIL_BULK_BY_EMAILS = "email/FAILED_SEND_EMAIL_BULK_BY_EMAILS";

export const SEND_EMAIL_BULK_BY_ROLE = "email/SEND_EMAIL_BULK_BY_ROLE";
export const SUCCEEDED_SEND_EMAIL_BULK_BY_ROLE = "email/SUCCEEDED_SEND_EMAIL_BULK_BY_ROLE";
export const FAILED_SEND_EMAIL_BULK_BY_ROLE = "email/FAILED_SEND_EMAIL_BULK_BY_ROLE";

export const GET_USER_SEND_LOGS = "email/GET_USER_SEND_LOGS";
export const SUCCEEDED_GET_USER_SEND_LOGS = "email/SUCCEEDED_GET_USER_SEND_LOGS";
export const FAILED_GET_USER_SEND_LOGS = "email/FAILED_GET_USER_SEND_LOGS";

export const GET_USER_SEND_DETAIL_LOGS = "email/GET_USER_SEND_DETAIL_LOGS";
export const SUCCEEDED_GET_USER_SEND_DETAIL_LOGS = "email/SUCCEEDED_GET_USER_SEND_DETAIL_LOGS";
export const FAILED_GET_USER_SEND_DETAIL_LOGS = "email/FAILED_GET_USER_SEND_DETAIL_LOGS";

// Email Template
export const GET_EMAIL_TEMPLATE = "email/GET_EMAIL_TEMPLATE";
export const SUCCEEDED_GET_EMAIL_TEMPLATE = 'email/SUCCEEDED_EMAIL_TEMPLATE';
export const FAILED_GET_EMAIL_TEMPLATE = 'email/FAILED_EMAIL_TEMPLATE';

export const CREATE_EMAIL_TEMPLATE = 'email/CREATE_EMAIL_TEMPLATE';
export const SUCCEEDED_CREATE_EMAIL_TEMPLATE = 'email/SUCCEEDED_CREATE_EMAIL_TEMPLATE';
export const FAILED_CREATE_EMAIL_TEMPLATE = 'email/FAILED_CREATE_EMAIL_TEMPLATE';

export const UPDATE_EMAIL_TEMPLATE = 'email/UPDATE_EMAIL_TEMPLATE';
export const SUCCEEDED_UPDATE_EMAIL_TEMPLATE = 'email/SUCCEEDED_UPDATE_EMAIL_TEMPLATE';
export const FAILED_UPDATE_EMAIL_TEMPLATE = 'email/FAILED_UPDATE_EMAIL_TEMPLATE';

export const DELETE_EMAIL_TEMPLATE = 'email/DELETE_EMAIL_TEMPLATE';
export const SUCCEEDED_DELETE_EMAIL_TEMPLATE = 'email/SUCCEEDED_DELETE_EMAIL_TEMPLATE';
export const FAILED_DELETE_EMAIL_TEMPLATE = 'email/FAILED_DELETE_EMAIL_TEMPLATE';

// Banner
export const GET_BANNER_LIST = "banner/GET_BANNER_LIST";
export const SUCCEEDED_GET_BANNER_LIST = "banner/SUCCEEDED_GET_BANNER_LIST";
export const FAILED_GET_BANNER_LIST = "banner/FAILED_GET_BANNER_LIST";

export const CREATE_BANNER = "banner/CREATE_BANNER";
export const SUCCEEDED_CREATE_BANNER = "banner/SUCCEEDED_CREATE_BANNER";
export const FAILED_CREATE_BANNER = "banner/FAILED_CREATE_BANNER";

export const UPDATE_BANNER = "banner/UPDATE_BANNER";
export const SUCCEEDED_UPDATE_BANNER = "banner/SUCCEEDED_UPDATE_BANNER";
export const FAILED_UPDATE_BANNER = "banner/FAILED_UPDATE_BANNER";

export const DELETE_BANNER = "banner/DELETE_BANNER";
export const SUCCEEDED_DELETE_BANNER = "banner/SUCCEEDED_DELETE_BANNER";
export const FAILED_DELETE_BANNER = "banner/FAILED_DELETE_BANNER";

export const BANNER_ACTIVE_TOGGLE = "banner/BANNER_ACTIVE_TOGGLE";
export const SUCCEEDED_BANNER_ACTIVE_TOGGLE = "banner/SUCCEEDED_BANNER_ACTIVE_TOGGLE";
export const FAILED_BANNER_ACTIVE_TOGGLE = "banner/FAILED_BANNER_ACTIVE_TOGGLE";

// DashBoard
export const GET_DASHBOARD_USER_STATUS = "users/GET_DASHBOARD_USER_STATUS";
export const SUCCEEDED_GET_DASHBOARD_USER_STATUS = "users/SUCCEEDED_GET_DASHBOARD_USER_STATUS";
export const FAILED_GET_DASHBOARD_USER_STATUS = "users/FAILED_GET_DASHBOARD_USER_STATUS";

export const GET_DASHBOARD_JOIN_USERS = "users/GET_DASHBOARD_JOIN_USERS";
export const SUCCEEDED_GET_DASHBOARD_JOIN_USERS = "users/SUCCEEDED_GET_DASHBOARD_JOIN_USERS";
export const FAILED_GET_DASHBOARD_JOIN_USERS = "users/FAILED_GET_DASHBOARD_JOIN_USERS";

export const GET_DASHBOARD_VISITORS_USERS = "users/GET_DASHBOARD_VISITORS_USERS";
export const SUCCEEDED_GET_DASHBOARD_VISITORS_USERS = "users/SUCCEEDED_GET_DASHBOARD_VISITORS_USERS";
export const FAILED_GET_DASHBOARD_VISITORS_USERS = "users/FAILED_GET_DASHBOARD_VISITORS_USERS";

export const GET_DASHBOARD_USER_STORES = "users/GET_DASHBOARD_USER_STORES";
export const SUCCEEDED_GET_DASHBOARD_USER_STORES = "users/SUCCEEDED_GET_DASHBOARD_USER_STORES";
export const FAILED_GET_DASHBOARD_USER_STORES = "users/FAILED_GET_DASHBOARD_USER_STORES";

export const GET_DASHBOARD_ANALYZE_STATUS = "users/GET_DASHBOARD_ANALYZE_STATUS";
export const SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS = "users/SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS";
export const FAILED_GET_DASHBOARD_ANALYZE_STATUS = "users/FAILED_GET_DASHBOARD_ANALYZE_STATUS";

export const GET_DASHBOARD_ANALYZE_STATUS_RESULTS = "users/GET_DASHBOARD_ANALYZE_STATUS_RESULTS";
export const SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS = "users/SUCCEEDED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS";
export const FAILED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS = "users/FAILED_GET_DASHBOARD_ANALYZE_STATUS_RESULTS";

export const GET_DASHBOARD_MONITORING_STATUS = "users/GET_DASHBOARD_MONITORING_STATUS";
export const SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS = "users/SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS";
export const FAILED_GET_DASHBOARD_MONITORING_STATUS = "users/FAILED_GET_DASHBOARD_MONITORING_STATUS";

export const GET_DASHBOARD_MONITORING_STATUS_RESULTS = "users/GET_DASHBOARD_MONITORING_STATUS_RESULTS";
export const SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS_RESULTS = "users/SUCCEEDED_GET_DASHBOARD_MONITORING_STATUS_RESULTS";
export const FAILED_GET_DASHBOARD_MONITORING_STATUS_RESULTS = "users/FAILED_GET_DASHBOARD_MONITORING_STATUS_RESULTS";

export const GET_DASHBOARD_USER_LIST = "users/GET_USER_LIST";
export const FULFILLED_DASHBOARD_USER_LIST = "users/FULFILLED_USER_LIST";
export const FAILED_DASHBOARD_USER_LIST = "users/FAILED_USER_LIST";

export const GET_DASHBOARD_QNA_COUNT = "dashboard/GET_DASHBOARD_QNA_COUNT";
export const SUCCEEDED_DASHBOARD_QNA_COUNT = "dashboard/SUCCEEDED_DASHBOARD_QNA_COUNT";
export const FAILED_DASHBOARD_QNA_COUNT = "dashboard/FAILED_DASHBOARD_QNA_COUNT";

// 리뷰레이 데모 요청 리스트 조회
export const GET_REVIEW_DEMOS = "demo/GET_REVIEW_DEMOS";
export const SUCCEEDED_GET_REVIEW_DEMOS = "user/SUCCEEDED_GET_REVIEW_DEMOS";
export const FAILED_GET_REVIEW_DEMOS = "user/FAILED_GET_REVIEW_DEMOS"

// 리뷰레이 데모 요청 상세 조회
export const GET_REVIEW_DEMOS_BY_ID = "demo/GET_REVIEW_DEMOS_BY_ID";
export const SUCCEEDED_GET_REVIEW_DEMOS_BY_ID = "user/SUCCEEDED_GET_REVIEW_DEMOS_BY_ID";
export const FAILED_GET_REVIEW_DEMOS_BY_ID = "user/FAILED_GET_REVIEW_DEMOS_BY_ID"

// 리뷰레이 데모요청 발급가능 유저 조회
export const GET_REVIEW_DEMOS_USERS = "demo/GET_REVIEW_DEMOS_USERS";
export const SUCCEEDED_GET_REVIEW_DEMOS_USERS = "user/SUCCEEDED_GET_REVIEW_DEMOS_USERS";
export const FAILED_GET_REVIEW_DEMOS_USERS = "user/FAILED_GET_REVIEW_DEMOS_USERS"

// 리뷰레이 데보 계정 발급
export const PATCH_REVIEW_DEMOS_COMPLETE = "demo/PATCH_REVIEW_DEMOS_COMPLETE";
export const SUCCEEDED_PATCH_REVIEW_DEMOS_COMPLETE = "user/SUCCEEDED_PATCH_REVIEW_DEMOS_COMPLETE";
export const FAILED_PATCH_REVIEW_DEMOS_COMPLETE = "user/FAILED_PATCH_REVIEW_DEMOS_COMPLETE"

// 리뷰레이 데보 계정 발급 취소
export const PATCH_REVIEW_DEMOS_CANCEL = "demo/PATCH_REVIEW_DEMOS_CANCEL";
export const SUCCEEDED_PATCH_REVIEW_DEMOS_CANCEL = "user/SUCCEEDED_PATCH_REVIEW_DEMOS_CANCEL";
export const FAILED_PATCH_REVIEW_DEMOS_CANCEL = "user/FAILED_PATCH_REVIEW_DEMOS_CANCEL"

export const GET_DASHBOARD_USER = "dashboard/GET_DASHBOARD_USER";
export const SUCCEEDED_GET_DASHBOARD_USER = "dashboard/SUCCEDED_GET_DASHBOARD_USER";
export const FAILED_GET_DASHBOARD_USER = "dashboard/FAILED_GET_DASHBOARD_USER";

export const GET_DASHBOARD_QNA = "dashboard/GET_DASHBOARD_QNA";
export const SUCCEEDED_GET_DASHBOARD_QNA = "dashboard/SUCCEDED_GET_DASHBOARD_QNA";
export const FAILED_GET_DASHBOARD_QNA = "dashboard/FAILED_GET_DASHBOARD_QNA";

export const GET_DASHBOARD_USER_MORE = "dashboard/GET_DASHBOARD_USER_MORE";
export const SUCCEEDED_GET_DASHBOARD_USER_MORE = "dashboard/SUCCEEDED_GET_DASHBOARD_USER_MORE";
export const FAILED_GET_DASHBOARD_USER_MORE = "dashboard/FAILED_GET_DASHBOARD_USER_MORE";

export const GET_DASHBOARD_USER_LOGIN_LOG_MORE = "dashboard/GET_DASHBOARD_USER_LOGIN_LOG_MORE";
export const SUCCEEDED_GET_DASHBOARD_USER_LOGIN_LOG_MORE = "dashboard/SUCCEEDED_GET_DASHBOARD_USER_LOGIN_LOG_MORE";
export const FAILED_GET_DASHBOARD_USER_LOGIN_LOG_MORE = "dashboard/FAILED_GET_DASHBOARD_USER_LOGIN_LOG_MORE";

//데이터 검증
export const GET_DATA_CONFIRM_TODAY_BEST = "data-confirm/GET_DATA_CONFIRM_TODAY_BEST";
export const SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST = "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST";
export const FAILED_GET_DATA_CONFIRM_TODAY_BEST = "data-confirm/FAILED_GET_DATA_CONFIRM_TODAY_BEST";

export const GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET = "data-confirm/GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET";
export const SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET= "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET";
export const FAILED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET = "data-confirm/FAILED_GET_DATA_CONFIRM_TODAY_BEST_PER_MARKET";

export const RECOLLECT_TODAY_BEST = "data-confirm/RECOLLECT_TODAY_BEST";
export const SUCCEEDED_RECOLLECT_TODAY_BEST = "data-confirm/SUCCEEDED_RECOLLECT_TODAY_BEST";
export const FAILED_RECOLLECT_TODAY_BEST = "data-confirm/FAILED_RECOLLECT_TODAY_BEST";

export const GET_DATA_CONFIRM_TREND_NEWS = "data-confirm/GET_DATA_CONFIRM_TREND_NEWS";
export const SUCCEEDED_GET_DATA_CONFIRM_TREND_NEWS = "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_TREND_NEWS";
export const FAILED_GET_DATA_CONFIRM_TREND_NEWS = "data-confirm/FAILED_GET_DATA_CONFIRM_TREND_NEWS";

export const RECOLLECT_TREND_NEWS = "data-confirm/RECOLLECT_TREND_NEWS";
export const SUCCEEDED_RECOLLECT_TREND_NEWS = "data-confirm/SUCCEEDED_RECOLLECT_TREND_NEWS";
export const FAILED_RECOLLECT_TREND_NEWS= "data-confirm/FAILED_RECOLLECT_TREND_NEWS";

export const GET_DATA_CONFIRM_PRODUCT_MONITORING = "data-confirm/GET_DATA_CONFIRM_PRODUCT_MONITORING";
export const SUCCEEDED_GET_DATA_CONFIRM_PRODUCT_MONITORING = "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_PRODUCT_MONITORING";
export const FAILED_GET_DATA_CONFIRM_PRODUCT_MONITORING = "data-confirm/FAILED_GET_DATA_CONFIRM_PRODUCT_MONITORING";

export const GET_DATA_CONFIRM_KEYWORD_MONITORING = "data-confirm/GET_DATA_CONFIRM_KEYWORD_MONITORING";
export const SUCCEEDED_GET_DATA_CONFIRM_KEYWORD_MONITORING = "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_KEYWORD_MONITORING";
export const FAILED_GET_DATA_CONFIRM_KEYWORD_MONITORING = "data-confirm/FAILED_GET_DATA_CONFIRM_KEYWORD_MONITORING";

export const RECOLLECT_KEYWORD_MONITORING = "data-confirm/RECOLLECT_KEYWORD_MONITORING";
export const SUCCEEDED_RECOLLECT_KEYWORD_MONITORING = "data-confirm/SUCCEEDED_RECOLLECT_KEYWORD_MONITORING";
export const FAILED_RECOLLECT_KEYWORD_MONITORING= "data-confirm/FAILED_RECOLLECT_KEYWORD_MONITORING";

export const RECOLLECT_PRDUCT_MONITORING = "data-confirm/RECOLLECT_PRDUCT_MONITORING";
export const SUCCEEDED_RECOLLECT_PRDUCT_MONITORING = "data-confirm/SUCCEEDED_RECOLLECT_PRDUCT_MONITORING";
export const FAILED_RECOLLECT_PRDUCT_MONITORING = "data-confirm/FAILED_RECOLLECT_PRDUCT_MONITORING";

export const GET_DATA_CONFIRM_TREND_KEYWORDS = "data-confirm/GET_DATA_CONFIRM_TREND_KEYWORDS";
export const SUCCEEDED_GET_DATA_CONFIRM_TREND_KEYWORDS = "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_TREND_KEYWORDS";
export const FAILED_GET_DATA_CONFIRM_TREND_KEYWORDS = "data-confirm/FAILED_GET_DATA_CONFIRM_TREND_KEYWORDS";

export const RECOLLECT_TREND_KEYWORD = "data-confirm/RECOLLECT_TREND_KEYWORD"
export const SUCCEEDED_RECOLLECT_TREND_KEYWORD = "data-confirm/SUCCEEDED_RECOLLECT_TREND_KEYWORD"
export const FAILED_RECOLLECT_TREND_KEYWORD = "data-confirm/FAILED_RECOLLECT_TREND_KEYWORD"

export const GET_TREND_KEYWORDS_FAILED = "data-confirm/GET_TREND_KEYWORDS_FAILED";
export const SUCCEEDED_GET_TREND_KEYWORDS_FAILED = "data-confirm/SUCCEEDED_GET_TREND_KEYWORDS_FAILED";
export const FAILED_GET_TREND_KEYWORDS_FAILED = "data-confirm/FAILED_GET_TREND_KEYWORDS_FAILED";

export const GET_DATA_CONFIRM_HOT_KEYWORD = "data-confirm/GET_DATA_CONFIRM_HOT_KEYWORD"
export const SUCCEEDED_GET_DATA_CONFIRM_HOT_KEYWORD= "data-confirm/SUCCEEDED_GET_DATA_CONFIRM_HOT_KEYWORD"
export const FAILED_GET_DATA_CONFIRM_HOT_KEYWORD = "data-confirm/FAILED_GET_DATA_CONFIRM_HOT_KEYWORD"

export const RECOLLECT_HOT_KEYWORD = "data-confirm/RECOLLECT_HOT_KEYWORD"
export const SUCCEEDED_RECOLLECT_HOT_KEYWORD = "data-confirm/SUCCEEDED_RECOLLECT_HOT_KEYWORD"
export const FAILED_RECOLLECT_HOT_KEYWORD = "data-confirm/FAILED_RECOLLECT_HOT_KEYWORD"

export const GET_DATA_CONFIRM_NAVER_ATTRIBUTE = 'data-confirm/GET_DATA_CONFIRM_NAVER_ATTRIBUTE"'
export const SUCCEEDED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE = 'data-confirm/SUCCEEDED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE"'
export const FAILED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE = 'data-confirm/FAILED_GET_DATA_CONFIRM_NAVER_ATTRIBUTE"'

export const GET_DATA_CONFIRM_CRAWL_LOG = "data-confirm/GET_DATA_CONFIRM_CRAWL_LOG"
export const SUCCEEDED_DATA_CONFIRM_CRAWL_LOG = "data-confirm/SUCCEEDED_DATA_CONFIRM_CRAWL_LOG"
export const FAILED_DATA_CONFIRM_CRAWL_LOG = "data-confirm/FAILED_DATA_CONFIRM_CRAWL_LOG"

export const RECOLLECT_DATA_CONFIRM_CRAWL_LOG = "data-confirm/RECOLLECT_DATA_CONFIRM_CRAWL_LOG"
export const SUCCEEDED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG = "data-confirm/SUCCEEDED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG"
export const FAILED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG = "data-confirm/FAILED_RECOLLECT_DATA_CONFIRM_CRAWL_LOG"

export const GET_REVIEW_DATA_CONFIRM_STATUS_CHECK = "review-data-confirm/GET_REVIEW_DATA_CONFIRM_STATUS_CHECK"
export const SUCCEEDED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK = "review-data-confirm/SUCCEEDED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK"
export const FAILED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK = "review-data-confirm/FAILED_GET_REVIEW_DATA_CONFIRM_STATUS_CHECK"

export const GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT = "review-data-confirm/GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT"
export const SUCCEEDED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT = "review-data-confirm/SUCCEEDED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT"
export const FAILED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT = "review-data-confirm/FAILED_GET_REVIEW_DATA_CONFIRM_FIRST_COLLECT"

export const GET_REVIEW_DATA_CONFIRM_DAILY_COLLECT = "review-data-confirm/GET_REVIEW_DATA_CONFIRM_DAILY_COLLECT"
export const SUCCEEDED_REVIEW_DATA_CONFIRM_DAILY_COLLECT = "review-data-confirm/SUCCEEDED_REVIEW_DATA_CONFIRM_DAILY_COLLECT"
export const FAILED_REVIEW_DATA_CONFIRM_DAILY_COLLECT = "review-data-confirm/FAILED_REVIEW_DATA_CONFIRM_DAILY_COLLECT"

export const REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT = "review-data-confirm/REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT"
export const SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT = "review-data-confirm/SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT"
export const FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT = "review-data-confirm/FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_PRODUCT"

export const REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE = "review-data-confirm/REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE"
export const SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE = "review-data-confirm/SUCCEEDED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE"
export const FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE = "review-data-confirm/FAILED_REVIEW_DATA_CONFIRM_RECOOLECT_COMPARE"

export const GET_CRAWL_API_CALL_STATS = 'crawl-api-call-stats/GET_CRAWL_API_CALL_STATS'
export const SUCCEEDED_GET_CRAWL_API_CALL_STATS = 'crawl-api-call-stats/SUCCEEDED_GET_CRAWL_API_CALL_STATS'
export const FAILED_GET_CRAWL_API_CALL_STATS = 'crawl-api-call-stats/FAILED_GET_CRAWL_API_CALL_STATS'

