import { IMenuParams } from 'components/common/constants';
import { Record } from 'immutable';

export interface ActionPayload {
  type: string
  payload: {
    pathVariables?: any,
    pathVariabless? : any,
    queryParameters?: any,
    body?: any,
    async?: boolean;
    callback?: { (success: boolean, data?: any): void }
  }
}

export interface IBaseList<T> {
  size: number
  list: T[] 
}

export interface ICommonCodeConvert {
  value:string;
  text: string;
}

export interface ICommonCode {
  value: string;
  key: string;
}

export interface StoreState {
  basics: BasicState;
  users: UserState;
  qna: QnaState;
  guide: GuideState;
  event: EventState;
  faq: FaqState;
  salonOn: SalonOnState;
  codeLists: CodeListsState;
  market: MarketState;
  sales: SalesState;
  notice: NoticeState;
  email: EmailState;
  emailTemplate: EmailTemplateState;
  userSales: UserSalesState;
  userLogs: UserLogsState;
  demo: DemoState;
  bestProduct: BestProductState;
  bestMarketData: BestProductPerMarketState;
  trendNews: TrendNewsState;
  trendKeyword: TrendKeywordState;
  productMonitoring: MonitoringProductState;
  keywordMonitoring: KeywordMonitoringState;
  hotKeyword500: HotKeyword500State;
  naverAttribute: NaverAttributeState;
  crawlLog: CrawlLogState
  reviewConfirm: ReviewConfirmState
  crawlApiCallStats: CrawlApiCallStatsState
}

export interface ILoginResult {
  error?: any;
  loading?: boolean;
}

interface ICommonCodeList {
  [key: string]: ICommonCode[];
  admin_user_marketing_type: ICommonCode[];
  product_list_sort: ICommonCode[];
  // use_guide: ICommonCode[];
  crawling_status_subject_code: ICommonCode[];
  function_provided: ICommonCode[];
  market_summary_type: ICommonCode[];
  social_login_type: ICommonCode[];
  event_search_type: ICommonCode[];
  extra_service: ICommonCode[];
  un_calculration_list_sort: ICommonCode[];
  collection_type: ICommonCode[];
  notice_type: ICommonCode[];
  market_api_key_name: ICommonCode[];
  crawling_status_message_code: ICommonCode[];
  sales_product_list_sort: ICommonCode[];
  admin_user_market_list: ICommonCode[];
  event_type: ICommonCode[];
  file_type: ICommonCode[];
  market_login_type: ICommonCode[];
  calculration_list_sort: ICommonCode[];
  market_type: ICommonCode[];
  market_compare_type: ICommonCode[];
  collector_type: ICommonCode[];
  crawling_location_code: ICommonCode[];
  admin_user_search_type: ICommonCode[];
  service_type: ICommonCode[];
  salon_on_type: ICommonCode[];
  sales_claim_list_sort: ICommonCode[];
  admin_user_role_type: ICommonCode[];
  mobile_search_type: ICommonCode[];
  sort_type: ICommonCode[];
  payment_type: ICommonCode[];
  date_time_field_search_type: ICommonCode[];
  promotion_payment: ICommonCode[];
  success_collection_type: ICommonCode[];
  voucher_type: ICommonCode[];
  sales_search_type: ICommonCode[];
  faq_type: ICommonCode[];
  payment_history_type: ICommonCode[];
  qna_type: ICommonCode[];
  market_login_mode: ICommonCode[];
  membership_item_type: ICommonCode[];
  membership_type: ICommonCode[];
  crawl_api_call_type: ICommonCode[];
}

export interface IMarket {
  market: string
  marketId: string
  name: string
  link: string
  subName: string
  logo: string
  logoLiteral: string
  color: string
  reviewCollectableCount: number
}

export interface IBasicState {
  menu: IMenuParams;
  user: any;
  loginResult?: ILoginResult;
  commonCodeList: ICommonCodeList;
  markets: IBaseList<IMarket>
}

export interface ISendLog {
  sendId: number;
  sendType: string;
  title: string;
  content: string;
  templateId: string;
  regDt: string;
}

export interface IDetailSendLog {
  sendId: number;
  uid: number;
  isSuccess: boolean;
  isInvalid: boolean;
  regDt: string;
  userId: string;
  userName: string;
  email: string;
  phone: string;
}

export interface IEmailState {
  logList: ISendLog[];
  sendList: IDetailSendLog[];
}

export interface IEmailTemplateState {
  list: EmailTemplateItem[]
}

export interface IUserLogsState {
  userLoginLogs: {
    content: IUserLogItem[];
    size: number;
    totalPages: number;
    totalElements: number;
  }
}

const LoginResultRecord = Record<ILoginResult>({
  error: null,
  loading: false
});

const BasicStateRecord = Record<IBasicState>({ 
  menu: {} as IMenuParams,
  user: null,
  loginResult: new LoginResultRecord(),
  commonCodeList: {} as ICommonCodeList,
  markets: {
    size: 0,
    list: []
  }
});

const EmailStateRecord = Record<IEmailState>({ 
  logList: [],
  sendList: [],
});

const EmailTemplateStateRecord = Record<IEmailTemplateState>({ 
  list: []
});

const UserSalesRecord = Record<UserSales>({ 
  userSalesData: []
});

const UserLogsRecord = Record<IUserLogsState>({
  userLoginLogs: {
    content: [],
    size: 0,
    totalPages: 0,
    totalElements: 0,
  }
});

export class LoginResult extends LoginResultRecord {
}

export class BasicState extends BasicStateRecord {
}

export class EmailState extends EmailStateRecord {
}

export class EmailTemplateState extends EmailTemplateStateRecord {
}

export class UserSalesState extends UserSalesRecord {
}
export class UserLogsState extends UserLogsRecord {  
}

export interface UserState {
  userList: {
    list: any[],
    page: IPage
  };
  dashboardUserData: {
    list: {
      content: any[],
      size: number;
      totalPages: number;
      totalElements: number;
    },
    joinDaily: {
      clientType: string;
      count: number;
      date: string;
    }[];
  };
  tosList: any[];
  paymentList: any[];
  codeList: any;
}


export interface UserSales {
  userSalesData: {
    userId: string;
    customerName: string;
    customerId: string;
    yearMonth: string;
    totalSales: number;
    marketDetail: {
      marketId: string;
      amount: number;
    }[]
  }[]
}

export interface QnaState {
  qnaData: {      
    page: IPage,
    list: any[]
  },
}

export interface SalonOnState {
  salonOnData: {      
    list: any[],
    page:IPage
  },
  tagData: {
    list: any[],
    page:IPage
  },

}

export interface GuideState {
  guideList: any
}
export interface EventState {
  eventList: any,
}

export interface FaqState {
  faqData: {      
    list: any[],
    size: number;
    page: IPage
  },
}
export interface CountTodayBest {
  marketId: string;
  complete: number;
  fail: number;
  regDt: string;
}

export interface TodayBestState {
  marketId: string;
  categoryBottom: string;
  categoryTop: string;
  categoryUrl: string,
}

export interface TodayBestListItem {
  marketId: string;
  count: CountTodayBest[];
  todayBests: TodayBestState[];
}

export interface BestProductState {
  bestData: IBaseList<TodayBestListItem>
}

export interface BestProductPerMarketState {
  marketData: {
    marketId: string;
    todayBestDtoList: TodayBestState [];
  };
}

export interface TrendNews{
  count: number;
  date: string;
}
export interface TrendNewsState{
  newsData: IBaseList<TrendNews>
}
export interface KeywordRankList {
  rank: number;
  keyword: string;
  categoryId: string;
  categoryName: string;
}

export interface TrendKeywordListItem{
  date: string;
  marketId: string;
  hour: number;
  todayKeywords: KeywordRankList[];
}
export interface TrendKeywordState{
  keywordData: IBaseList<TrendKeywordListItem>
  failedKeywordData: IBaseList<string>
}

export interface MonitoringKeywords{
  id: number;
  productId: string;
  monitoringProductId: number;
  itemType: string;
  itemValue: string;
  isFailed: boolean;
}

export interface KeywordMonitoringState{
  keywordMonitoringData:{
    total: number;
    failedCount: number;
    monitoringKeywords: MonitoringKeywords [];
  }
}
export interface MonitoringProducts{
  id: number;
  productId: string;
  productUrl: string;
  productName: string;
}

export interface MonitoringProductState{
  productMonitoringData:{
    total: number;
    failedCount: number;
    monitoringProducts: MonitoringProducts [];
  }
}

export interface HotKeywords{
  categoryId: string;
  categoryName: string;
}

export interface HotKeyword500State{
  hotKeywordData:{
    startDt: string;
    endDt: string;
    startCollectedDate: string;
    latestDateTime: string;
    totalCategories: number;
    miscollectedCategories: number;
    collectedKeywords: number;
    hotKeywords: HotKeywords [];
  }
}

export interface NaverAttributeState{
  naverAttributeData:{
    date: string;
    totalCategories: number;
    naverAttributeCategories: number;
  }
}

export interface ICrawlLogData {
  id: number
  serviceType: string
  requestType: string
  date: string
  time: number
  marketId?: string
  path: string
  requestBody: string
  timeMillisecond: number
  status: 'SUCCESS' | 'FAIL' | 'WAIT'
  regDt: string
  updateDt: string
}

export interface CrawlLogState {
  crawlLog: IBaseList<ICrawlLogData>
}

export type ProductStatusType = 'BEFORE' | 'COLLECTING' | 'COLLECTED' | 'ANALYZED' 
export type TableNameType = 'COMPARE' | 'PRODUCT'

export interface IReviewConfirmProduct {
  id: number;
  customerId: string;
  productId: string;
  marketId: string;
  marketName: string;
  productName: string;
  productUrl: string;
  status: ProductStatusType;
  reviewCount: number;
  purchaseReviewCount: number;
  notCollectedReviewCount: number;
  lastRegDt: string;
  failCount: number;
  interval: number;
  tableName: TableNameType
}

export interface IReviewConfirmStatusCheck {
  productAnalyzedCount: number;
  productCollectingCount: number;
  productCollectedCount: number;
  compareAnalyzedCount: number;
  compareCollectingCount: number;
  compareCollectedCount: number;
  customerProducts: IReviewConfirmProduct[]
}

export interface IReviewConfirmFirstCollect {
  allProductCount: number;
  allPurchaseReviewCount: number;
  allReviewCount: number;
  allNotCollectedProductCount: number;
  allNotCollectedReviewCount: number;
  customerProducts: IReviewConfirmProduct[]
}

export interface IUnCollectedProduct {
  productId: string;
  marketId: string;
  marketName: string;
  productName: string;
  productUrl: string;
}

export interface IUnCollectedReviewProduct {
  productId: string;
  marketId: string;
  marketName: string;
  increaseReviewCount: number;
  collectedReviewCount: number;
  unCollectedReviewCount: number;
}

export interface IReviewConfirmDailyCollect {
  allProductCount: number;
  collectedProductCount: number;
  failedProductCount: number;
  increaseReviewCount: number;
  uncollectedReviewCount: number;
  uncollectedProducts: IUnCollectedProduct[]
  uncollectedReviews: IUnCollectedReviewProduct[]
}

export interface ReviewConfirmState {
  statusCheck: IReviewConfirmStatusCheck
  firstCollect: IReviewConfirmFirstCollect
  dailyCollect: IReviewConfirmDailyCollect
}

export interface CrawlApiCallStatsState {
  crawlApiCallStats: ICrawlApiCallStats
}

export interface ICrawlApiCallStats {
  trueCountSum: number
  falseCountSum: number
  totalCountSum: number
  crawlApiCallStats: IBaseList<ICrawlApiCallStat> & { page: IPage }
  
}

export interface ICrawlApiCallStat {
  serviceType: string
  rootType: string
  apiType: string
  marketId: string
  date: string
  count: number
  isCrawl: boolean
  updateDt: string
}

export interface CodeListsState {
  codeLists: any,
}

export interface SalesState {
  salesInfo: any,
}

export interface IMarket {
  market: string;
  marketId: string;
  name: string;
  subName: string
  logo: string;
  logoLiteral: string;
  color: string;
  reviewCollectableCount: number;
}

export interface MarketState {
  marketData: {
    list: IMarket[],
    page: IPage,
  }
}

export interface DemoState {
  demoList: {      
    page: IPage,
    list: any[]
  },
}

export interface IPage {
  empty: boolean,
  first:boolean,
  hasNext:boolean,
  hasPrevious:boolean,
  last: boolean,
  page: number,
  size: number,
  totalElements: number,
  totalPages: number,
}

export const initPage = {
  empty: true,
  first: false,
  hasNext: false,
  hasPrevious: false,
  last: false,
  page: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
}

export interface NoticeState {
  noticeList: {
    list: any[],
    page: IPage
  }
}

export interface Email {

}

export interface EmailTemplateItem {
  regDt: string;
  updateDt: string;
  templateId: string;
  templateName: string;
  template: string;
}


export interface IUserLogItem {
  userId: string;
  userRole: string;
  clientType: string;
  agent: string;
  timestamp: string;
  clientIp: string;
  customerName: string;
}

export interface IMembershipType {
  irankMemberships: 'FREE' | 'BASIC' | 'STANDARD' | 'PREMIUM' | 'ENTERPRISE'
  reviewMemberships: 'FREE' | 'DEMO' | 'ENTERPRISE' | 'PREMIUM' | 'FREE_BETA'
}

export type IUserCooperationType = 'COMMON' | 'SHOPLINKER'

export type IMembershipItemsType = {
  groupBy : string,
  item: string,
  itemName: string
  itemValue: string
  limitCount: number
  membership: IMembershipType['irankMemberships'] | IMembershipType['reviewMemberships']
  option: IMembershipItemType
}

export const MEMBERSHIP_OPTION_NAME: { [key in IMembershipItemType]: string } = {
  OPTION_1: ' Grade 1',
  OPTION_2: ' Grade 2',
  OPTION_3: ' Grade 3',
  OPTION_4: ' Grade 4',
  OPTION_5: ' Grade 5',
  OPTION_6: ' Grade 6',
  OPTION_7: ' Grade 7',
  OPTION_8: ' Grade 8',
}

export type IMembershipItemType = 'OPTION_1' | 'OPTION_2' | 'OPTION_3' | 'OPTION_4' | 'OPTION_5' | 'OPTION_6' | 'OPTION_7' | 'OPTION_8'

export interface IUserMembership {
  cycle: number
  discountPrice: number
  discountRate: number
  id: number
  isEnd: boolean
  isUnSubscribe: boolean
  membershipItems: IMembershipItemsType[]
  membership: IMembershipType['irankMemberships'] | IMembershipType['reviewMemberships']
  membershipEndDate: string | null
  membershipStartDate: string | null
  nextPayment: string | null
  nowPayment: string | null
  option: IMembershipItemType
  price: number
  reviewProductCount: number | null
  scheduleDt: string | null
  serviceType: 'IRANK'
  membershipCount: {
    keywordCount : {
      total: number
      month: number
    },
    productCount : {
      total: number
      month: number
      product: number
    },
    recommendCount: {
      total: number
      month: number
      product: number
    },
    monitoringCount: {
      product: number
      keyword: number
      group: number
    }
  }
}

export interface ICounts {
  currentCount: number
  item: string
  limitCount: number
}

export interface IItems {
  item : string
  itemName : string
  limitCount: number
}

export interface IUserReviewrayMembership {
  contractProductCount: number
  contractReviewCount: number
  counts: ICounts[]
  id: number
  isEnd: boolean
  items: IItems[]
  membership: IMembershipType['reviewMemberships']
  membershipEndDate: string | null
  membershipStartDate: string | null
  option: IMembershipItemType
  productChangeCount: number| null
  productChangeLimit: number | null
  serviceType: 'REVIEWRAY'
}

export type TUserTos = {
  applyDate: string
  content: string
  priority: number
  regDt: string
  required: boolean
  title: string
  uid: number
  updateDt: string | null
  url: string
  useYn: boolean
}

export type TCustomer = {
  customerId: string
  customerName: string
  customerType: string
}

export type TAgreements = {
  agreementYn: boolean
  regDt: string
  uid: number
  updateDt: string
  userTos: TUserTos
}

export type TRoleType = 'USER'|'OPERATOR'|'DEVELOPER'|'TESTER'|'ADMIN'

export type TRoles = {
  role: TRoleType
  uid: number
}

export type TMemberDetails = {
  agreements: TAgreements[]
  customer: TCustomer
  customerType: string
  email: string
  mailAgreementYn: boolean
  phone: string
  roles: TRoles[]
  smsAgreementYn: boolean
  socialOnly: boolean
  uid: string
  userId: string
  userName: string
  cooperationType: string
}

export interface IUser {
  customerMembership : {
    irankMembership: IUserMembership,
    reviewMembership: IUserReviewrayMembership
  }
  memberDetails: TMemberDetails
  cooperationType: string
}

export type TReviewProductType = {
  analyzeReviewCount: number
  baseDate: string
  cast: number
  category: string
  categoryId: string
  categoryName: string
  deliveryFee: number
  id: number
  isFavorite: boolean
  isExpired: boolean
  isUnsubscribe: boolean
  membershipEndDate: string
  membershipStartDate: string
  paymentDate: string
  productId: string
  productName: string
  productPrice: number
  productSalePrice: number
  productSaleRate: number
  productThumbnailUrl: string
  productUrl: string
  purchaseReviewCount: number
  regDt: string
  reviewCount: number
  sellingStatus: string
  starPoint: number
  status: 'BEFORE' | 'COLLECTING' | 'COLLECTED' | 'ANALYZED'
  storeId: string
  storeName: string
  marketId: string
}

export interface IReviewrayProducts {
  list: TReviewProductType[],
  page: IPage,
}

export const initReviewrayProducts = {
  list: [],
  page: initPage
}