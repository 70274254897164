import { combineEpics } from 'redux-observable';
import { createAction,handleActions } from 'redux-actions';

// actions
import * as actionTypes from './types/actions';
import { CrawlApiCallStatsState, initPage } from './types';

import epicService, { API_URL, IEpicReqParams, AjaxMethods } from './types/epics';
import { AjaxResponse } from 'rxjs/ajax';

const action = {
  requestGetCrawlApiCallStats: createAction(actionTypes.GET_CRAWL_API_CALL_STATS),
  succeededGetCrawlApiCallStats: createAction(actionTypes.SUCCEEDED_GET_CRAWL_API_CALL_STATS),
  failedGetCrawlApiCallStats: createAction(actionTypes.FAILED_GET_CRAWL_API_CALL_STATS),
}

const initialState: CrawlApiCallStatsState = {
    crawlApiCallStats: {
      trueCountSum: 0,
      falseCountSum: 0,
      totalCountSum: 0,
      crawlApiCallStats: {
        size: 0,
        list: [],
        page: initPage
      },
      
    }  
  };

const reducer = handleActions<CrawlApiCallStatsState>(
  {
    [actionTypes.SUCCEEDED_GET_CRAWL_API_CALL_STATS]: (state, action): CrawlApiCallStatsState => {
      return {
        ...state,
        crawlApiCallStats: action.payload.crawlApiCallStats
      }
    },
  },
  initialState
  );

const requestGetCrawlApiCallStatsEpic = (actions$: any) => {
  const reqParams: IEpicReqParams = {
    actions: {
      request: actionTypes.GET_CRAWL_API_CALL_STATS,
      success: (res: AjaxResponse) => action.succeededGetCrawlApiCallStats({ crawlApiCallStats: res.response.data }),
      fail: actionTypes.FAILED_GET_CRAWL_API_CALL_STATS
    },
    url: API_URL.CRAWL_API_CALL_STATS,
    method: AjaxMethods.GET
  }
  return epicService.request(actions$, reqParams);
  ;
}

const epic = combineEpics(
  requestGetCrawlApiCallStatsEpic,
);
export{action, epic};
export default reducer;