import styled, { css } from "styled-components";

interface IStyleProps {
  fontSize?: string
  fontWeight?: number

}

const Wrap = styled.section`
  width: 100%;
  padding: 25px;

  display: flex;
  flex-direction: column;
  row-gap: 25px;
  justify-content: flex-start;
  align-items: center;
`

const DatasWrap = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.span<IStyleProps>`
  ${({ fontSize,  fontWeight }) => css`
    font-size: ${fontSize || '12px'};
    font-weight: ${fontWeight || 700};
  `}
`

const ValidationCrawlApiCallStatFilterWrap = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  column-gap: 25px;
  row-gap: 25px;
  
`

const ValidationCrawlApiCallStatFilterCommonBox = styled.div`
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > .ui.dropdown:not(.button)>.default.text {
    color: #333;
  }
`

const FlatpickerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const Flatpicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  column-gap: 3px;
`

const InfoWrap = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 40px;
`

const InfoItem = styled.li`
  flex: 1;
  padding: 20px;
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
  box-shadow: 
    0px 3px 3px -2px rgb(0 0 0 / 20%), 
    0px 3px 4px 0px rgb(0 0 0 / 14%), 
    0px 1px 8px 0px rgb(0 0 0 / 12%);

  width: 100%;
  display: flex;
  justify-content: space-between;

  & > span:first-child {
    font-size: 12px;
    font-weight: 500;
  }

  & > span:first-last {
    font-size: 13px;
    font-weight: 600;
  }
`



export const ValidationCrawlApiCallStatStyles = {
  Wrap,
  Text,
  DatasWrap,
  ValidationCrawlApiCallStatFilterCommonBox,
  ValidationCrawlApiCallStatFilterWrap,
  FlatpickerWrap,
  Flatpicker,
  InfoWrap,
  InfoItem,
}