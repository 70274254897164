import React from "react"
import useTrendKeyword from "hooks/useTrendKeyword";
import {
  Table,
  TableWrapper,
} from "../styles/CommonStyle/Common.style";
import FullScreenLoader from "components/common/loader/FullScreenLoader";
import TrendKeywordItem from "./TrendKeywordItem";

interface IProps {
  isLoading: boolean
  showAll: boolean
}

const TrendKeywordTable: React.FC<IProps> = ({ isLoading, showAll }: IProps) => {

  const { 
    trendKeyword, 
  } = useTrendKeyword();
  const { keywordData } = trendKeyword;

  const keywordsList = keywordData.list.map((item) => item.todayKeywords);
  
  const maxKeywords = Math.max(
    ...keywordsList.map((keywords) => keywords.length)
  );

  const latestHour =
    keywordData?.list && keywordData.list[0] ? keywordData.list[0].hour : 0;

  const formatTime = (time: number): string => {
    return time.toString().padStart(2, "0");
  };

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <TableWrapper>
    <Table>
      <thead>
        <tr>
          <th> Rank/Hour </th>
          {Array.from({ length: latestHour + 1 }, (_, index) => (
            <th key={index}>{formatTime(latestHour - index)}시</th>
          ))}{" "}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: maxKeywords }, (_, rowIndex) => (
          <tr key={rowIndex}>
            <th>{rowIndex + 1}</th>
            {
              keywordsList
                .map((keywords, index) => 
                  <TrendKeywordItem 
                    isLastRank={rowIndex + 1 === maxKeywords}
                    showAll={showAll}
                    isFirstHour={index + 1 === keywordsList.length}
                    keywordRankList={keywords[rowIndex]} 
                  />
              )
            }
          </tr>
        ))}
      </tbody>
    </Table>
  </TableWrapper>
}

export default TrendKeywordTable