import React, { useEffect, useState } from "react";
import TodayBest from "./TodayBest/TodayBest";
import Monitoring from "./Monitoring/Monitoring";
import CommerceNews from "./CommerceNews/CommerceNews";
import TrendKeyword from "./TrendKeyword/TrendKeyword";
import HotKeyword500 from "./HotKeyword/HotKeyword500";
import NaverAttribute from "./NaverAttribute/NaverAttribute";
import { IndexStyles as Styled } from "./styles/Dataconfirm.style";
import Calendar from "../common/calendar/Calendar";
import moment from "moment";
import RealTime from "./realTime/RealTime";
import useBasics from "hooks/useBasics";

const Validation: React.FC = () => {
  const {
    basics : {
      markets
    },
    requestMarkets
  } = useBasics()

  useEffect(() => {
    if (!markets || markets.size === 0) {
      requestMarkets()
    }
  }, [])

  const [currentMenu, setCurrentMenu] = useState<string>("TodayBest");
  const [searchDate, setSearchDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const onChangeEventDate = (e: any) => {
    const date = moment(e[0]).format("YYYY-MM-DD");
    setSearchDate(date);
  };
  return (
    <Styled.Container>
      {currentMenu !== "HotKeyword500" && currentMenu !== "NaverAttribute" && (
        <Calendar
          type="main"
          searchDate={searchDate}
          label="day"
          onChangeEventDate={onChangeEventDate}
        />
      )}
      <Styled.ButtonContainer>
        <Styled.Button
          as={
            currentMenu === "TodayBest" ? Styled.SelectedButton : Styled.Button
          }
          onClick={() => setCurrentMenu("TodayBest")}
        >
          베스트 상품
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "RealTime" ? Styled.SelectedButton : Styled.Button
          }
          onClick={() => setCurrentMenu("RealTime")}
        >
          실시간 수집
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "Monitoring" ? Styled.SelectedButton : Styled.Button
          }
          onClick={() => setCurrentMenu("Monitoring")}
        >
          모니터링
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "CommerceNews"
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => setCurrentMenu("CommerceNews")}
        >
          커머스 뉴스
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "TrendKeyword"
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => setCurrentMenu("TrendKeyword")}
        >
          트렌드 키워드
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "HotKeyword500"
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => setCurrentMenu("HotKeyword500")}
        >
          핫 키워드 500
        </Styled.Button>
        <Styled.Button
          as={
            currentMenu === "NaverAttribute"
              ? Styled.SelectedButton
              : Styled.Button
          }
          onClick={() => setCurrentMenu("NaverAttribute")}
        >
          네이버 상품 속성
        </Styled.Button>
      </Styled.ButtonContainer>
      <Styled.Article>
        {currentMenu === "TodayBest" && <TodayBest searchDate={searchDate} />}
        {currentMenu === "RealTime" && <RealTime searchDate={searchDate} />}
        {currentMenu === "Monitoring" && <Monitoring searchDate={searchDate} />}
        {currentMenu === "CommerceNews" && (
          <CommerceNews searchDate={searchDate} />
        )}
        {currentMenu === "TrendKeyword" && (
          <TrendKeyword searchDate={searchDate} />
        )}
        {currentMenu === "HotKeyword500" && <HotKeyword500 />}
        {currentMenu === "NaverAttribute" && <NaverAttribute />}
      </Styled.Article>
    </Styled.Container>
  );
};
export default Validation;
